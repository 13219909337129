import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, DialogActions, DialogContent, FormControlLabel, FormGroup, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import Estimationtask from '../model/EstimationTask';
import { BarChart, BarPlot, ChartsReferenceLine } from '@mui/x-charts';
import CalculateEstimationForConfidenceLevel from './confidence';
import Estimation from '../model/Estimation';

interface EditTaskComponentProps {
    estimation: Estimation,
    task: Estimationtask;
    close: () => void;
    tasks: { [id: string]: Estimationtask };
}


const calculatePERT = (task:Estimationtask) => {
  if (!task.effortoptimistic || !task.effortrealistic || !task.effortpessimistic) {
    return 0;
  }
  return (task.effortoptimistic! + 4 * task.effortrealistic! + task.effortpessimistic!) / 6;
};

const EditTaskComponent: React.FC<EditTaskComponentProps> = (props) => {
    const [localTask, setLocalTask] = useState<Estimationtask>({ ...props.task });
    const [data, setData] = useState<any>([]);
    const [xaxis, setXaxis] = useState<any>([]);
    const [pert, setPert] = useState<number>(0);
    const [effortconfident, setEffortConfident] = useState<number>(0);
    const confidenceLabel = ' @ ' + localTask.target_confidence_level + '%';

    useEffect(() => {
      localTask.target_confidence_level = props.estimation.target_confidence_level;
      localTask.effort = calculatePERT(localTask)
      localTask.effortconfident = CalculateEstimationForConfidenceLevel(localTask.effortoptimistic!, localTask.effortrealistic!, localTask.effortpessimistic!, localTask.target_confidence_level!)
      setPert(localTask.effort);
      setEffortConfident(localTask.effortconfident);
      
      setData ([
        { 
          type: 'bar',
          data: [ 
            localTask.effortoptimistic ?? 0, 
            localTask.effortrealistic ?? 0 , 
            localTask.effortpessimistic ?? 0
          ]
        },
        {
          type: 'line',
          color: 'green',
          data: [ pert, pert, pert ]
        },
        {
          type: 'line',
          color: 'blue',
          data: [ effortconfident, effortconfident, effortconfident ]
        }
      ]);
      setXaxis([
        { 
          id: 'estimation',
          data: [ 'optimistic', 'realistic', 'pessimistic'],
          scaleType: 'band'
        }
      ]);
    }, [localTask, effortconfident, pert, props.estimation.target_confidence_level]);

    return (
        <Dialog open={true} maxWidth='lg' fullWidth={true} onClose={props.close} >
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          <Stack direction="row">
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant='h6'>Summary</Typography>
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel control={
                    <Switch                  
                      checked={localTask.hascost}
                      onChange={()=>setLocalTask({ ...localTask, hascost: !localTask.hascost })}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} 
                    label="Has Cost" disabled={true} />
                  <FormControlLabel control={
                    <Switch
                      checked={localTask.haseffort}
                      onChange={()=>setLocalTask({ ...localTask, haseffort: !localTask.haseffort })}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} 
                    label="Has Effort" disabled={true} />
                </FormGroup>
              </Grid>
              <Grid item>
                <TextField
                  label="Name"
                  value={localTask.name}
                  sx={{width:400}} disabled={true}
                  onChange={(e) => setLocalTask({ ...localTask, name: e.target.value })}
                />
              </Grid>
              <Grid item >
                <TextField
                  label="Description"
                  sx={{width:400}}
                  multiline
                  rows={4} disabled={true}
                  value={localTask.description}
                  onChange={(e) => setLocalTask({ ...localTask, description: e.target.value })}
                />
              </Grid>
              <Grid item>
                <TextField
                    label="Assumptions"
                    sx={{width:400}}
                    multiline
                    rows={4} disabled={true}
                    value={localTask.assumptions}
                    onChange={(e) => setLocalTask({ ...localTask, assumptions: e.target.value })}
                  />
              </Grid>
            </Grid>
            {localTask.hascost && <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant='h6'>Cost</Typography>
              </Grid>
              <Grid item>
                <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                  <TextField
                      label="Cost"
                      type="number"
                      value={localTask.cost}
                      sx={{width:150}} disabled={true}
                      onChange={(e) => setLocalTask({ ...localTask, cost: Number(e.target.value) })}
                    />
                  <TextField
                      label="Quantity"
                      type="number"
                      value={localTask.quantity}
                      sx={{width:150}} disabled={true}
                      onChange={(e) => setLocalTask({ ...localTask, quantity: Number(e.target.value) })}
                    />
                </Stack>
              </Grid>
            </Grid>}
            {localTask.haseffort && <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography variant='h6'>Effort</Typography>
                </Grid>
                <Grid item>
                  <Stack direction='row' spacing={{ xs: 1, sm: 2 }}>
                    <TextField
                        label="Optimistic"
                        type="number"
                        value={localTask.effortoptimistic}
                        sx={{width:150}} disabled={true}
                        onChange={(e) => setLocalTask({ ...localTask, effortoptimistic: Number(e.target.value) })}
                      />
                    <TextField
                        label="Realistic"
                        type="number"
                        value={localTask.effortrealistic}                      
                        sx={{width:150}} disabled={true}
                        onChange={(e) => setLocalTask({ ...localTask, effortrealistic: Number(e.target.value) })}
                      />
                    <TextField
                        label="Pessimistic"
                        type="number"
                        value={localTask.effortpessimistic}
                        sx={{width:150}} disabled={true}
                        onChange={(e) => setLocalTask({ ...localTask, effortpessimistic: Number(e.target.value) })}
                      />
                  </Stack>
                </Grid>
                <BarChart
                    width={500}
                    height={300}
                    series={data}
                    xAxis={xaxis}
                >
                  <BarPlot />
                  <ChartsReferenceLine
                    y={pert}
                    label={'PERT '+pert.toFixed(2)}
                    lineStyle={{ stroke: 'red' }}
                  />
                  <ChartsReferenceLine
                    y={effortconfident}
                    label={effortconfident.toFixed(2) + confidenceLabel}
                    lineStyle={{ stroke: 'red' }}
                  />
                </BarChart>
              
              </Grid>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.close()}>Close</Button>
        </DialogActions>
      </Dialog>
    );
};

export default EditTaskComponent;
import React from 'react';
import { Grid, Paper, Box, ThemeProvider, createTheme } from '@mui/material';
import Logo from '../logo.svg';
import { lightTheme } from './themes';

interface RightModalFormProps {
    children: React.ReactNode;
}

const RightModalForm: React.FC<RightModalFormProps> = ({ children }) => {

    const imageStyle: React.CSSProperties = {
        backgroundImage: 'url(splash.webp)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Full screen height
    };

    const paperStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full screen height
    };

    const logoStyle: React.CSSProperties = {
        marginBottom: '32px', // Equivalent to theme.spacing(4)
        width: '50%', // Adjust the size of the logo as needed
    };

    const theme = createTheme(lightTheme);
    
    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" style={{ height: '100vh' }}>
                <Grid item xs={false} sm={4} md={7} style={imageStyle} />
            
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box style={paperStyle} alignContent="center">
                        <img src={Logo} alt="logo" style={logoStyle} />
                        {children}
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default RightModalForm;

import { CreateWrapper, EditWrapper, ListWrapper } from './Wrapper';
import { AutocompleteInput, ReferenceField, ReferenceInput } from 'react-admin';
import icon from '@mui/icons-material/VerifiedUser';

const name = "Systemuseraccess";
const title = "System User Access";
const recordRepresentation = (record: any) => record.systemtenant_id + " - " + record.systemuser_id;

const create = (
    <CreateWrapper title={title}>
        <ReferenceInput reference="Systemtenant" source="systemtenant_id">
            <AutocompleteInput label="Tenant" />
        </ReferenceInput>
        <ReferenceInput reference="Systemuser" source="systemuser_id">
            <AutocompleteInput label="User" />
        </ReferenceInput>
    </CreateWrapper>
);

const edit = (
    <EditWrapper title={title}>
        <ReferenceInput reference="Systemtenant" source="systemtenant_id">
            <AutocompleteInput label="Tenant" />
        </ReferenceInput>
        <ReferenceInput reference="Systemuser" source="systemuser_id">
            <AutocompleteInput label="User" />
        </ReferenceInput>
    </EditWrapper>
);

const list = (
    <ListWrapper title={title}>
        <ReferenceField reference="Systemtenant" source="systemtenant_id" label="Tenant"/>
        <ReferenceField reference="Systemuser" source="systemuser_id" label="User"/>
    </ListWrapper>
);

const options = { label: title };
const useraccess = { name, create, edit, list, recordRepresentation, icon, options };
export default useraccess;

import {
    CREATE,
    UPDATE,
    DELETE,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
  } from "react-admin";
import { Options } from "@ra-data-prisma/dataprovider";
import camelCase from "lodash/camelCase";
import pluralize from "pluralize";
  
export const graphqlOptions: Options = {
    queryDialect: "typegraphql",
    mutationOperationNames: {
        typegraphql: {
        [CREATE]: (resource) => `createOne${resource.name}`,
        [UPDATE]: (resource) => `updateOne${resource.name}`,
        [DELETE]: (resource) => `deleteOne${resource.name}`,
        },
    },
    queryOperationNames: {
        typegraphql: {
        [GET_LIST]: (resource) => `${pluralize(camelCase(resource.name))}`,
        [GET_MANY]: (resource) => `${pluralize(camelCase(resource.name))}`,
        [GET_MANY_REFERENCE]: (resource) => `${pluralize(camelCase(resource.name))}`,
        [GET_ONE]: (resource) => `${camelCase(resource.name)}`,
        },
    },
    resourceViews: {
        
    }
};
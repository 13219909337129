import { CreateWrapper, ListWrapper } from '../system/Wrapper';
import { Store, TextField, TextInput, required } from 'react-admin';
import icon from '@mui/icons-material/SupervisorAccount';
import Edit from './Edit';
import { ISettings } from '../settings';

const name = "Estimation";
const title = "Estimation";
const recordRepresentation = (record: any) => record.name;

const create = (
    <CreateWrapper title={title}>
        <TextInput source="name" validate={[required()]} />
    </CreateWrapper>
);

const list = (
    <ListWrapper title={title}>
        <TextField source="name" />
    </ListWrapper>
);

const options = { label: title };

export default function getEstimation(settings:ISettings, store:Store)
{ 
    const edit = <Edit settings={settings} store={store}/>;
    return { name, create, edit, list, recordRepresentation, icon, options }
};

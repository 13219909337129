import { Box, Typography } from '@mui/material';
import { Create, Datagrid, Edit, EditButton, List, ListButton, SimpleForm, TabbedForm, TopToolbar, useGetOne, useRecordContext, useTranslate } from 'react-admin';
import Systemuser from '../model/Systemuser';
import IBaseModel from '../model/IBaseModel';
import { FormatDate } from '../FormatDate';

export const Wrapper = ({ title, children }: { title:string, children: React.ReactNode }) =>{
    return (
        <Box sx={{padding: '1em'}}>
            <Typography variant="h4">{title}</Typography>
            {children}
        </Box>
    );
};

const AsideElement = ({ id, label, date }: { id?:string|null|undefined, label:string, date?:Date|null|undefined }) => {
    const translate = useTranslate();
    const { data } = useGetOne<Systemuser>('Systemuser', { id: id??'' }, { enabled: !!id });
    const formattedDate = FormatDate(date);

    if(!id) return (<></>);

    return <Typography variant="body1">
        {label} <b>{data?.idp_givenname} {data?.idp_familyname}</b>
        {formattedDate && (' ' + translate('pages.system.wrapper.on'))} {formattedDate}
        </Typography>;
};

const Aside = () => {
    const translate = useTranslate();
    const record = useRecordContext<IBaseModel>();

    return <Box sx={{ width: '200px', margin: '1em' }}>
        <Typography variant="h6">Record Information</Typography>
        <AsideElement 
            id={record?.owner_id}
            label={translate('pages.system.wrapper.owner')} 
        />
        <AsideElement 
            id={record?.createdby_id} 
            label={translate('pages.system.wrapper.createdby')} 
            date={record?.createdon}
        />
        <AsideElement 
            id={record?.modifiedby_id} 
            label={translate('pages.system.wrapper.modifiedby')}
            date={record?.modifiedon} 
        />
    </Box>
};

export const CreateWrapper = ({ title, defaultValues, children }: { title:string, defaultValues?:any, children: React.ReactNode }) => {
    return (
    <Wrapper title={title}>
        <Create title="" actions={<WrapperEditActions/>}>
            <SimpleForm defaultValues={defaultValues}>
                {children}
            </SimpleForm>
        </Create>
    </Wrapper>
    );
};

const WrapperEditActions = () => (
    <TopToolbar>
        <ListButton  />
    </TopToolbar>
);

export const EditWrapper = ({ title, children }: { title:string, children: React.ReactNode }) => {
    return (
    <Wrapper title={title}>
        <Edit aside={<Aside/>} actions={<WrapperEditActions/>}>
            <SimpleForm>
                {children}
            </SimpleForm>            
        </Edit>
    </Wrapper>
    );
};

export const EditTabbedWrapper = ({ title, children, aside=true }: { title:string, children: React.ReactNode, aside?:boolean }) => {
    return (
    <Wrapper title={title}>
        <Edit aside={aside?<Aside/>:<></>} actions={<WrapperEditActions/>}>
            <TabbedForm>
                {children}
            </TabbedForm>            
        </Edit>
    </Wrapper>
    );
}

export const ListWrapper = ({ title, children }: { title:string, children: React.ReactNode }) => {
    return (
    <Wrapper title={title}>
        <List hasCreate={true} title="">
            <Datagrid>
                {children}
                <EditButton />
            </Datagrid>
        </List>
    </Wrapper>
    );
};
import { StrictModeDroppable } from "./StrictModeDroppable";
import { TaskListProps } from "./interface";
import { useEffect, useState } from "react";
import EstimationTask from "../model/EstimationTask";
import { SingleTaskComponent } from "./SingleTaskComponent";
import { Grid } from "@mui/material";
import { Draggable } from "@hello-pangea/dnd";

const getListStyle = (isDraggingOver:boolean) => ({
    background: isDraggingOver ? "lightblue" : "",
    padding: 5,
    marginLeft: 10,
    borderRadius: "10px",
    width: '100%'
  });

const getChildren = (tasks: { [id: string]: EstimationTask }, parentId: string | null): Array<EstimationTask> => {
    return Object.values(tasks).filter(t => t.parentestimationtask_id === parentId);
}
 
export const TaskListComponent: React.FC<TaskListProps> = ({ estimation, task, index, tasks, taskTypes, updateTask, addSubtask, deleteTask, showCosts, showEffort }) => {
    const [children, setChildren] = useState<EstimationTask[]>([]);

    useEffect(() => {
        const parentId = task ? task.id : null;
        const newChildren = getChildren(tasks, parentId);
        if(newChildren.length === 0) return;
        newChildren.sort((a, b) => a.order - b.order);
        setChildren(newChildren);
    },[tasks, task]);

    const id = task ? task.id : 'root';

    if(children.length === 0) 
        return null;
    else if (children.length===1 && getChildren(tasks, children[0].id).length>0){
        return (
            <>
                <SingleTaskComponent showCosts={showCosts} showEffort={showEffort} key={children[0].id} estimation={estimation} task={children[0]} index={0} tasks={tasks} updateTask={updateTask} addSubtask={addSubtask} deleteTask={deleteTask} isSingleTask={true} taskTypes={taskTypes}/>
                <TaskListComponent showCosts={showCosts} showEffort={showEffort} index={0} estimation={estimation} task={children[0]} tasks={tasks} updateTask={updateTask} addSubtask={addSubtask} deleteTask={deleteTask} taskTypes={taskTypes} />
            </>
            );
        }
    else
        return (        
            <Grid container>
                <StrictModeDroppable droppableId={id} type={task?.estimationtasktype_id} key={id}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} 
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {children.map((child, i) => (
                                <>
                                    <Draggable key={child.id} draggableId={child.id} index={i}>
                                        {(innerprovided) => (
                                        <div
                                            ref={innerprovided.innerRef}
                                            {...innerprovided.draggableProps}
                                            style={{
                                            userSelect: "none",
                                            padding: 5,
                                            margin: 5,
                                            marginLeft: 10,
                                            paddingLeft: 15,
                                            paddingRight: 0,
                                            marginRight: 0,
                                            backgroundColor: "#fff",
                                            color: "black",
                                            borderRadius: "10px",                                            
                                            ...innerprovided.draggableProps.style,
                                            // border: isHovered?'1px solid #ccc':''
                                            }}
                                        >   
                                            <SingleTaskComponent showCosts={showCosts} showEffort={showEffort} key={child.id} estimation={estimation} task={child} index={0} tasks={tasks} updateTask={updateTask} addSubtask={addSubtask} deleteTask={deleteTask} isSingleTask={true} dragHandleProps={innerprovided.dragHandleProps} taskTypes={taskTypes}/>
                                            <TaskListComponent showCosts={showCosts} showEffort={showEffort} index={i} estimation={estimation} task={child} tasks={tasks} updateTask={updateTask} addSubtask={addSubtask} deleteTask={deleteTask} taskTypes={taskTypes} />
                                        </div>
                                        )}
                                    </Draggable>
                                </>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            </Grid>
        );
}

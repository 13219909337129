import { CreateWrapper, EditWrapper, ListWrapper } from '../system/Wrapper';
import { ReferenceField, ReferenceInput, TextField, TextInput, required } from 'react-admin';
import icon from '@mui/icons-material/AccountCircle';
import { useLocation } from 'react-router';

const name = "Estimationtasktype";
const title = "Estimation Task Type";
const recordRepresentation = (record: any) => record.name;

const CreateComponent = () => {    
    const location = useLocation();
    const { parentId } = location.state || {};
    const defaultValues = parentId ? { estimation_id: parentId } : {};

    return (
        <CreateWrapper title={title} defaultValues={defaultValues}>
            <ReferenceInput reference="Estimation" source="estimation_id" disabled={parentId as boolean} />
            <ReferenceInput reference="Estimationtasktype" source="parentestimationtasktype_id" label="Parent"/>
            <TextInput source="name" validate={[required()]} />
            <TextInput source="description" />
        </CreateWrapper>
    )
};

const edit = (
    <EditWrapper title={title}>
        <ReferenceInput reference="Estimationtasktype" source="parentestimationtasktype_id" label="Parent"/>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="description" />
    </EditWrapper>
);

const list = (
    <ListWrapper title={title}>
        <TextField source="name" label="Name"/>
        <ReferenceField reference="Estimationtasktype" source="parentestimationtasktype_id" label="Parent"/>
    </ListWrapper>
);

const options = { label: title };
const estimationtasktype = { name, create: CreateComponent, edit, list, recordRepresentation, icon, options };
export default estimationtasktype;

import { CreateWrapper, EditWrapper, ListWrapper } from './Wrapper';
import { ReferenceField, ReferenceInput } from 'react-admin';
import icon from '@mui/icons-material/VerifiedUser';

const name = "Systemuseraccessrole";
const title = "System User Access Role";
const recordRepresentation = (record: any) => record.systemtenant_id + " - " + record.systemuser_id;

const create = (
    <CreateWrapper title={title}>
        <ReferenceInput reference="Systemtenant" source="systemtenant_id" label="Tenant"/>
        <ReferenceInput reference="Systemuseraccess" source="systemuseraccess_id" label="User Access"/>
        <ReferenceInput reference="Systemuserrole" source="role_id" label="User Role"/>
    </CreateWrapper>
);

const edit = (
    <EditWrapper title={title}>
        <ReferenceInput reference="Systemtenant" source="systemtenant_id" label="Tenant"/>
        <ReferenceInput reference="Systemuser" source="systemuser_id" label="User"/>
    </EditWrapper>
);

const list = (
    <ListWrapper title={title}>
        <ReferenceField reference="Systemuseraccess" source="systemuseraccess_id" label="User Access">
            <ReferenceField reference="Systemuser" source="systemuser_id" />
            {" - "}
            <ReferenceField reference="Systemtenant" source="systemtenant_id" />
        </ReferenceField> 
        <ReferenceField reference="Systemuserrole" source="role_id" label="Role"/>
    </ListWrapper>
);

const options = { label: title };
const useraccessrole = { name, create, edit, list, recordRepresentation, icon, options };
export default useraccessrole;

import { useRef, StrictMode } from 'react';
import { Admin, AuthProvider, CustomRoutes, Resource, Store } from "react-admin"
import { useDataProvider } from "@ra-data-prisma/dataprovider";

// Authentication
import Keycloak from 'keycloak-js';
import { keycloakAuthProvider } from '../keycloakAuthProvider';
import { IProfile } from '../model/Profile';

// GraphQL
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { withLifecycleCallbacks } from '../data/withLifecycleCallbacks';

// Layout
import './App.css';
import { Layout } from '../layout';
import { darkTheme, lightTheme } from '../layout/themes';
import { ISettings } from '../settings';
import { graphqlOptions } from '../graphql';
import base64ConverterHandlers from '../data/base64ConverterHandler';

// Components
import LoadingScreen from '../LoadingScreen';

import { Dashboard } from '../dashboard/Dashboard';
import { GithubSettings }  from '../settings/GithubSettings';
import { Route } from 'react-router-dom';
import { GithubCopilot } from '../performance/GithubCopilot';
import { Suggestion } from '../settings/Suggestion';
import { RequestAccess } from '../settings/RequestAccess';
import { UserImpersonate } from '../user/UserImpersonate';

// Routes
import SystemModuleResource from '../system/module';
import SystemUserRole from '../system/userrole';
import SystemUser from '../system/user';
import SystemTenant from '../system/tenant';
import SystemUserAccess from '../system/useraccess';
import SystemUserAccessLicense from '../system/useraccesslicense';
import SystemUserAccessRole from '../system/useraccessrole';
import SystemLicense from '../system/license';
import Estimationtasktype from '../estimation/estimationtasktype';
import getEstimation from '../estimation/estimation';
import i18nProvider from './i18nProvider';

interface AppProps {
  settings: ISettings, 
  profile: IProfile, 
  keycloak: Keycloak,
  store: Store
}

function App({ settings, profile, keycloak, store }: AppProps) {
  const authProvider = useRef<AuthProvider>();  
  authProvider.current = keycloakAuthProvider(keycloak);

  const apolloHttpLink = new HttpLink({ uri: settings.url_graphql });
  const apolloAuthContext = setContext(async (_, {headers}) => {
    const jwt_token = store.getItem('keycloak-token')
    return {
        headers: {
            ...headers,
            Authorization: jwt_token ? `Bearer ${jwt_token}` : ''
        },
    }
  });
  const client = new ApolloClient({      
      link: apolloAuthContext.concat(apolloHttpLink),
      cache: new InMemoryCache()
    });

  const prismaDataProvider = useDataProvider({ ...graphqlOptions, client: client, });
  const dataProvider = withLifecycleCallbacks(prismaDataProvider, [...base64ConverterHandlers])

  const layout = (props: any) => <Layout {...props} profile={profile} />;
  const dashboard = (props: any) => <Dashboard {...props} profile={profile} />;

  if(!profile){
    console.debug('🔄 Loading Profile...');
    return <LoadingScreen/>;
  }else{
    console.debug('🚀 Welcome to PrimeFactor!');

    const estimation = getEstimation(settings, store);

    return (
    <StrictMode>
      <Admin 
          disableTelemetry 
          store={store}
          authProvider={authProvider.current}
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
          theme={lightTheme} darkTheme={darkTheme} lightTheme={lightTheme} defaultTheme="light"
          layout={layout} 
          dashboard={dashboard}
          >
            <Resource {...SystemTenant} />
            <Resource {...SystemUser} />
            <Resource {...SystemModuleResource} />
            <Resource {...SystemUserRole} />
            <Resource {...SystemUserAccess} />
            <Resource {...estimation} />
            <Resource {...Estimationtasktype} />
            <Resource {...SystemUserAccessLicense} />
            <Resource {...SystemUserAccessRole} />
            <Resource {...SystemLicense} />
            <CustomRoutes>
              <Route key="githubDashboard" path="/Githubcopilotstats" element={<GithubCopilot settings={settings} store={store}/>} />
              
              <Route key="sysadminimpersonate" path="/sysadmin/impersonate" element={<UserImpersonate settings={settings} store={store} />} />
              
              <Route key="suggestion" path="/suggestion" element={<Suggestion settings={settings} store={store}/>} />
              
              <Route key="requestaccess" path="/settings/requestaccess" element={<RequestAccess settings={settings} store={store}/>} />
              <Route key="settingsgithub" path="/settings/github" element={<GithubSettings settings={settings} store={store} />} />
            </CustomRoutes>
        </Admin>
      </StrictMode>
    );
  }
}

export default App;
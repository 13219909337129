import { useEffect, useState } from "react";
import { ISettings } from "../settings"
import RightModalForm from "../layout/RightModalForm";
import { Alert, Avatar, Box, Button, Input, Stack, Typography, styled } from "@mui/material";
import LoadingScreen from "../LoadingScreen";
import { Store } from "react-admin";

interface GithubConfigurationProps {
    settings: ISettings,
    store: Store
}

interface IGithubError{
    message: string;
    success: boolean;
    description: string;
}

const GithubConfiguration = ({settings, store}:GithubConfigurationProps) => {     
    const [error, setError] = useState<IGithubError|null>(null);
    const url = new URL(window.location.href);
    const hashSegments = url.hash.split("?");
    const params = new URLSearchParams(hashSegments[1]);
    const code = params.get("code");
    const installationId = params.get("installation_id");
    const setupAction = params.get("setup_action");

    const [context, setContext] = useState<any>(null);

    const installGithub = async () => {        
        
        const name = context.name?context.name:context.login;
        const email = context.email?context.email:(document.getElementById('email') as HTMLInputElement).value;
        if(!name || !email) return;
        
        const response = await fetch(settings.url_graphql + `api/github/install?installation_id=${installationId}&name=${name}&email=${email}`);
        const data = await response.json();
        if (data.success) {
            store.setItem('keycloak-token', data.impersonatedUser.access_token);
            store.setItem('keycloak-refreshToken', data.impersonatedUser.refresh_token);
            store.setItem('keycloak-idToken', data.impersonatedUser.id_token);
            console.dir('User impersonated successfully, refreshing page...');

            window.location.href = '/#/Githubcopilotstats';
            window.location.reload();
        }
    }
    
    useEffect(() => {
        const getGihubContext = async () => {
            try{
                const response = await fetch(settings.url_graphql + `api/github/context?code=${code}&installation_id=${installationId}&setup_action=${setupAction}`);
                if(!response.ok){
                    const data = await response.json() as IGithubError;
                    setError(data);
                }else{
                    const data = await response.json();
                    if (data.success) {
                        setContext(data.context);
                    }
                }
            }catch(e:any){
                // const data = await response.json() as IGithubError;
                // setError(data);
                console.dir(e);
                console.error(e);
            }
        }

        getGihubContext();                
    }, [settings, code, installationId, setupAction]);

    if(context){        
        console.dir(context);
    }

    const Item = styled(Box)(({ theme }) => ({
        ...theme.typography.body1,
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        }));
       
    if(!context) 
        return <LoadingScreen />
    else
        return (
        <RightModalForm>
            <Stack spacing={2} direction="column" justifyContent="center" alignItems="center" padding={5}>
                {context && !context.email &&
                    <>
                        <Item>
                            <Avatar src={context.avatar_url} sx={{ width: 128, height: 128 }}/>
                        </Item>
                        
                        <Item>
                            <Typography variant="h5">
                                Hi, @{context.login}!
                            </Typography>
                            One last step... it seems that your github email is private, please enter the email you wish to use with PrimeFactor.
                        </Item>

                        <Item>
                            <Stack direction="column" spacing={2}>
                                <Input id="email" type="email" fullWidth required placeholder="email" />
                                <Button onClick={installGithub} color="primary" variant="contained" fullWidth>Launch</Button>
                            </Stack>
                        </Item>
                    </>
                }
                {error && <Alert severity="error"><b>{error.message}</b><br/>{error.description}</Alert>}
            </Stack>
        </RightModalForm>
    );
}

export default GithubConfiguration;
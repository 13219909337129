import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    AccountCircle,
    Assessment,
    Domain,
    GitHub,
    Insights,
    Lightbulb,
    Settings,
    Speed,
    SupervisorAccount,
    ViewModule,
} from '@mui/icons-material';
import {
    useTranslate,
    Menu,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';
import SubMenu from './SubMenu';
import { IProfile } from '../model/Profile';

interface LayoutMenuProps extends MenuProps 
{
    profile: IProfile;
}

interface IMenuState{
    menuExercice: boolean;
    menuContent: boolean;
    menuAdministration: boolean;
    menuSysadmin: boolean;
    menuTactical: boolean;
    menuOperational: boolean;
}


type MenuName = 'menuExercice' | 'menuContent' | 'menuAdministration'  | 'menuSysadmin' | 'menuTactical' | 'menuOperational';

const LayoutMenu = ({ dense = false, profile }: LayoutMenuProps) => {

    const getInitialState = ():IMenuState => {
        const storedState = localStorage.getItem('menuState');
        if (storedState) {
            return JSON.parse(storedState);
        } else {
            return {
                menuExercice: false,
                menuContent: false,
                menuAdministration: false,
                menuSysadmin: false,
                menuTactical: false,
                menuOperational: false,
            };
        }
    }

    const [state, setState] = useState(getInitialState());

    useEffect(() => {
        localStorage.setItem('menuState', JSON.stringify(state));
    }, [state]);
    
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Menu
            sx={{
                width: open ? 250 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: (theme:any) =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }), 
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                backgroundColor: 'secondary' // Change background color to secondary
            }}
            dense={dense}
            hasDashboard
        >
            <DashboardMenuItem dense={dense} />
            { profile?.licensedmodules.filter(module => module.startsWith('tactical.')).length > 0 &&
            <SubMenu
                handleToggle={() => handleToggle('menuTactical')}
                isOpen={state.menuTactical}
                name="modules.tactical.index"
                icon={<Insights />}                
                dense={dense}
            >
                { profile?.licensedmodules.indexOf('tactical.estimation') >= 0 &&
                <MenuItemLink
                    to="/Estimation"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`modules.tactical.estimation`, {
                        smart_count: 2,
                    })}
                    dense={dense}
                    leftIcon={<Assessment />}
                />
                }
            </SubMenu>
            }
            { profile?.licensedmodules.filter(module => module.startsWith('operational.')).length > 0 &&
                <SubMenu
                    handleToggle={() => handleToggle('menuOperational')}
                    isOpen={state.menuOperational}
                    name="modules.operational.index"
                    icon={<Speed />}                
                    dense={dense}
                >
                    { profile?.licensedmodules.indexOf('operational.githubcopilot') >= 0 &&
                    <MenuItemLink
                        to="/Githubcopilotstats"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`modules.operational.githubcopilotstats`, {
                            smart_count: 2,
                        })}
                        dense={dense}
                        leftIcon={<GitHub />}
                    />
                    }
                </SubMenu>
            }
            <Box sx={{ flexGrow: 1 }} />
            { profile?.roles.indexOf('systemadmin') >= 0 &&
                <SubMenu
                    handleToggle={() => handleToggle('menuSysadmin')}
                    isOpen={state.menuSysadmin}
                    name="menu.sysadmin.index"
                    icon={<Speed />}                
                    dense={dense}
                >
                    <MenuItemLink
                        to="/Systemtenant"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`menu.sysadmin.tenant`)}
                        dense={dense}
                        leftIcon={<Domain />}                    
                    />
                    <MenuItemLink
                        to="/Systemuser"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`menu.sysadmin.user`)}
                        dense={dense}
                        leftIcon={<AccountCircle />}                    
                    />
                    <MenuItemLink
                        to="/Systemuserrole"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`menu.sysadmin.role`)}
                        dense={dense}
                        leftIcon={<SupervisorAccount />}                    
                    />
                    <MenuItemLink
                            to="/Systemmodule"
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`menu.sysadmin.module`)}
                            dense={dense}
                            leftIcon={<ViewModule />}                    
                        />
                </SubMenu>
            }
            
            <MenuItemLink
                    to="/suggestion"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`menu.suggestion`)}
                    dense={dense}
                    leftIcon={<Lightbulb />}                    
                />
            <MenuItemLink
                    to="/settings/github"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`menu.settings`)}
                    dense={dense}
                    leftIcon={<Settings />}
                />
            {/* Add your menu items here 
            <SubMenu
                handleToggle={() => handleToggle('menuExercice')}
                isOpen={state.menuExercice}
                name="pos.menu.exercice"
                icon={<AssignmentIndIcon />}                
                dense={dense}
            >
                <MenuItemLink
                    to="/client"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.client.name`, {
                        smart_count: 2,
                    })}
                    dense={dense}
                    leftIcon={<GroupIcon />}
                />
                <MenuItemLink
                    to="/clientnote"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.clientnote.name`, {
                        smart_count: 2,
                    })}
                    dense={dense}
                    leftIcon={<NoteIcon/>}
                />
                <MenuItemLink
                    to="/program"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.program.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ForkRightIcon />}                    
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuContent')}
                isOpen={state.menuContent}
                name="pos.menu.content"
                icon={<TopicIcon />} 
                dense={dense}
            >
                <MenuItemLink
                    to="/tool"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.tool.name`, {
                        smart_count: 2,
                    })}
                    dense={dense}
                    leftIcon={<HomeRepairServiceIcon />}
                />
                <MenuItemLink
                    to="/programprofile"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.programprofile.name`, {
                        smart_count: 2,
                    })}
                    dense={dense}
                    leftIcon={<AltRouteIcon />}
                />
                <MenuItemLink
                    to="/solutioncomparision"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.solutioncomparision.name`, {
                        smart_count: 2,
                    })}
                    dense={dense}
                    leftIcon={<CompareIcon />}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuAdministration')}
                isOpen={state.menuAdministration}
                name="pos.menu.administration"
                icon={<AssignmentIndIcon />}                
                dense={dense}
            >
                <MenuItemLink
                    to="/systemtenant"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.systemtenant.name`, {
                        smart_count: 2,
                    })}
                    dense={dense}
                    leftIcon={<GroupWorkIcon />}
                />
                <MenuItemLink
                    to="/systemuser"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.systemuser.name`, {
                        smart_count: 2,
                    })}
                    dense={dense}
                    leftIcon={<PeopleIcon />}
                />
            </SubMenu>
            */}

            {/* 
            <Box sx={{ flexGrow: 1 }} />
            <MenuItemLink
                    to="/present"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`pos.menu.present`)}
                    dense={dense}
                    leftIcon={<CoPresentIcon />}
                />
            */}
        </Menu>
    );
};

export default LayoutMenu;

import { sqrt } from 'mathjs';

// Function to calculate the inverse of the CDF for the standard normal distribution
function inverseCDF(p: number): number {
    if (p <= 0 || p >= 1) {
        throw new Error('p must be between 0 and 1');
    }
    const a1 = -39.69683028665376;
    const a2 = 220.9460984245205;
    const a3 = -275.9285104469687;
    const a4 = 138.357751867269;
    const a5 = -30.66479806614716;
    const a6 = 2.506628277459239;

    const b1 = -54.47609879822406;
    const b2 = 161.5858368580409;
    const b3 = -155.6989798598866;
    const b4 = 66.80131188771972;
    const b5 = -13.28068155288572;

    const c1 = -0.007784894002430293;
    const c2 = -0.3223964580411365;
    const c3 = -2.400758277161838;
    const c4 = -2.549732539343734;
    const c5 = 4.374664141464968;
    const c6 = 2.938163982698783;

    const d1 = 0.007784695709041462;
    const d2 = 0.3224671290700398;
    const d3 = 2.445134137142996;
    const d4 = 3.754408661907416;

    const p_low = 0.02425;
    const p_high = 1 - p_low;

    let q, r;

    if (p < p_low) {
        q = sqrt(-2 * Math.log(p)) as number;
        return (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
               ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
    } else if (p <= p_high) {
        q = p - 0.5;
        r = q * q;
        return (((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q /
               (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
    } else {
        q = sqrt(-2 * Math.log(1 - p)) as number;
        return -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
               ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
    }
}

export default function CalculateEstimationForConfidenceLevel(O: number, M: number, P: number, confidence: number): number {
    const alpha = (1 + confidence / 100) / 2;  // Convert confidence level to a two-tailed alpha
    const Z = inverseCDF(alpha);

    const TE = (O + 4 * M + P) / 6;
    const SD = (P - O) / 6;
    const maxDays = TE + Z * SD;

    return maxDays;
}
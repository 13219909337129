import React, { useEffect } from 'react';
import { Grid, Button, Paper, Box, ThemeProvider, createTheme } from '@mui/material';
import Logo from '../logo.svg'; // Path to your logo image
import { lightTheme } from './themes';
import { ISettings } from '../settings';
import { I18nProvider, LocalesMenuButton, useLocaleState } from 'react-admin';

const versionStyle: React.CSSProperties = {
  fontSize: '9px',
  color: 'gray',
  paddingTop: '20px',
};  

const Login: React.FC<{ init: () => void, register: () => void, i18n: I18nProvider, settings?: ISettings }> = ({ init, register, settings, i18n }) => {

  const imageStyle: React.CSSProperties = {
    backgroundImage: 'url(splash.webp)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh', // Full screen height
  };

  const paperStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full screen height
  };

  const logoStyle: React.CSSProperties = {
    marginBottom: '32px', // Equivalent to theme.spacing(4)
    width: '50%', // Adjust the size of the logo as needed
  };

  const theme = createTheme(lightTheme);
  const locales = i18n?.getLocales?.() || [];
  const [locale] = useLocaleState();
  const [translations, setTranslations] = React.useState({} as any);
  
  useEffect(() => {
    i18n?.changeLocale(locale).then(() => { 
      setTranslations( { login: i18n.translate('pages.login.login'), register: i18n.translate('pages.login.register')})
  } );
  }, [locale, i18n]);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" style={{ height: '100vh' }}>
        <Grid item xs={false} sm={4} md={7} style={imageStyle} />
      
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box style={paperStyle} alignContent="center">
            <img src={Logo} alt="logo" style={logoStyle} />
            <Grid container direction="row" alignItems="center" justifyContent="center" >
              <Box sx={{m:2}}>
                <Button variant="contained" color="primary" onClick={() => init()}>
                  {translations.login}
                </Button>
              </Box>
              <Box sx={{m:2}}>
                <Button variant="contained" color="secondary" onClick={() => register()}>
                  {translations.register}
                </Button>
              </Box>
            </Grid>
            <LocalesMenuButton languages={locales} />
            <span style={versionStyle}>UX {settings?.version} / API {settings?.gqlversion}</span>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;

import { CreateWrapper, EditWrapper, ListWrapper } from './Wrapper';
import { TextField, TextInput, required } from 'react-admin';
import icon from '@mui/icons-material/SupervisorAccount';

const name = "Systemuserrole";
const title = "System User Role";
const recordRepresentation = (record: any) => record.name;

const create = (
    <CreateWrapper title={title}>
        <TextInput source="name" validate={[required()]} />
    </CreateWrapper>
);

const edit = (
    <EditWrapper title={title}>
        <TextInput source="name" validate={[required()]} />
    </EditWrapper>
);

const list = (
    <ListWrapper title={title}>
        <TextField source="name" />
    </ListWrapper>
);

const options = { label: title };

const userrole = { name, create, edit, list, recordRepresentation, icon, options };
export default userrole;

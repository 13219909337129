export const WordPerLine = [
    { language: 'javascript', words: 6, wpm: 28 },
    { language: 'python', words: 5, wpm: 30 },
    { language: 'java', words: 7, wpm: 22 },
    { language: 'c#', words: 7, wpm: 22 },
    { language: 'c++', words: 6, wpm: 20 },
    { language: 'php', words: 5, wpm: 25 },
    { language: 'ruby', words: 4, wpm: 30 },
    { language: 'typescript', words: 6, wpm: 25 },
    { language: 'swift', words: 5, wpm: 27 },
    { language: 'go', words: 5, wpm: 28 },
    { language: 'kotlin', words: 5, wpm: 26 },
    { language: 'rust', words: 5, wpm: 27 },
    { language: 'scala', words: 5, wpm: 26 },
    { language: 'dart', words: 5, wpm: 28 },
    { language: 'r', words: 4, wpm: 29 },
    { language: 'matlab', words: 4, wpm: 24 },
    { language: 'perl', words: 5, wpm: 26 },
    { language: 'lua', words: 4, wpm: 31 },
    { language: 'haskell', words: 3, wpm: 32 },
    { language: 'shell', words: 4, wpm: 27 },
    { language: 'powershell', words: 4, wpm: 25 },
    { language: 'objective-c', words: 6, wpm: 21 },
    { language: 'sql', words: 8, wpm: 19 },
    { language: 'yaml', words: 3, wpm: 33 },
    { language: 'json', words: 3, wpm: 33 },
    { language: 'xml', words: 3, wpm: 32 },
    { language: 'html', words: 3, wpm: 33 },
    { language: 'css', words: 3, wpm: 33 },
    { language: 'scss', words: 3, wpm: 32 },
    { language: 'less', words: 3, wpm: 32 },
    { language: 'stylus', words: 3, wpm: 32 },
    { language: 'markdown', words: 3, wpm: 34 },
    { language: 'graphql', words: 3, wpm: 32 },
    { language: 'groovy', words: 4, wpm: 28 },
    { language: 'perl6', words: 5, wpm: 26 },
    { language: 'bash', words: 4, wpm: 27 },
    { language: 'awk', words: 4, wpm: 28 },
    { language: 'sed', words: 4, wpm: 29 },
    { language: 'makefile', words: 4, wpm: 26 },
    { language: 'dockerfile', words: 4, wpm: 27 },
    { language: 'plaintext', words: 3, wpm: 35 },
    { language: 'apacheconf', words: 3, wpm: 30 },
    { language: 'nginx', words: 3, wpm: 30 },
    { language: 'ini', words: 3, wpm: 34 },
    { language: 'properties', words: 3, wpm: 34 },
    { language: 'java-properties', words: 3, wpm: 34 },
    { language: 'gradle', words: 3, wpm: 31 },
    { language: 'toml', words: 3, wpm: 33 },
    { language: 'jsx', words: 6, wpm: 24 },
    { language: 'tsx', words: 6, wpm: 24 },
    { language: 'vue', words: 4, wpm: 29 },
    { language: 'svelte', words: 4, wpm: 30 },
    { language: 'pug', words: 3, wpm: 32 },
    { language: 'ejs', words: 3, wpm: 32 },
    { language: 'handlebars', words: 3, wpm: 31 },
    { language: 'twig', words: 3, wpm: 31 },
    { language: 'smarty', words: 3, wpm: 31 },
    { language: 'liquid', words: 3, wpm: 32 },
    { language: 'erb', words: 4, wpm: 28 },
    { language: 'haml', words: 3, wpm: 32 },
    { language: 'slim', words: 3, wpm: 33 },
    { language: 'coffeescript', words: 4, wpm: 29 },
    { language: 'livescript', words: 4, wpm: 29 },
    { language: 'elixir', words: 4, wpm: 28 },
    { language: 'elm', words: 4, wpm: 30 }
];

export interface ISettings {
    version: string;
    gqlversion: string,
    url_idp: string;
    url_graphql: string;
    idp_realm: string;
    idp_clientid: string;
    github_appname: string;
}

export async function fetchSettings(): Promise<ISettings> {
    const response = await fetch('/settings.json');
    const data = await response.json();
    
    const gresponse = await fetch(data.url_graphql + 'version',{ mode: 'cors', });
    const gversion = (await gresponse.json()).version;

    return {
        version: data.version,
        gqlversion: gversion,
        url_idp: data.url_idp,
        url_graphql: data.url_graphql,
        idp_realm: data.idp_realm,
        idp_clientid: data.idp_clientid,
        github_appname: data.github_appname
    };
}
import * as React from 'react';
import { FC, createElement } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
    icon: FC<any>;
    title?: string;
    subtitle?: ReactNode;
    children?: ReactNode;
    sx?: any;
}

const boxActiveStyle = {
    position: 'relative',
    overflow: 'hidden',
    padding: '38px',
    display: 'flex',
    justifyContent: 'center',  
    '& .icon': {
        color: 'secondary.main',
    },
    color: 'text.secondary',
    opacity: 0.25,
    minHeight: 52,
    alignItems: 'center',
    textAlign: 'center',
}

const CardPlaceholder = ({ icon, title, subtitle, children, sx }: Props) => (
    <Box sx={boxActiveStyle}>
        <Box width="3em" className="icon">
            {createElement(icon, { fontSize: 'large' })}
        </Box>
        <Box>
            <Typography variant="h5" component="h2">
                {title}
            </Typography>
        </Box>
    </Box>
);

export default CardPlaceholder;
import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Estimationtasktype from '../model/Estimationtasktype';
import { useGetRecordId } from 'react-admin';
import { Alert } from '@mui/material';

interface CreateTaskButtonProps {
  parentId: string | null;
  parentTaskType: string | null;
  clickHandler: (parentId: string | null, taskType: Estimationtasktype) => void;
  taskTypes: Estimationtasktype[];
}

export const CreateTaskButton:  React.FC<CreateTaskButtonProps> = ({ parentId, parentTaskType, clickHandler, taskTypes }:CreateTaskButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const recordId = useGetRecordId();

  if(!taskTypes) return <></>;
 
  const options = taskTypes.filter(t => t.estimation_id === recordId.toString() && t.parentestimationtasktype_id === parentTaskType);

  if (!options || options.length === 0) {
    if(parentId === null)
      return <Alert severity="warning">No Task Type Defined for this Estimation</Alert>;
    else
      return <></>;
  }

  const handleClick = () => {
    clickHandler(parentId?parentId.toString():null, options[selectedIndex]);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant={options.length>1?'outlined':'text'}
        ref={anchorRef} size='small' color='secondary'
      >
        <Button onClick={handleClick} size='small' color='secondary'>Add {options[selectedIndex]?.name}</Button>
        {options.length>1 &&
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
        </Button>
        }
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
import { useI18nProvider } from "react-admin";

export const FormatDate = (date: Date|null|undefined) => {
    const i18nProvider = useI18nProvider();
    if (!date) return '';
    const locale = i18nProvider.getLocale();
    
    let validDate: Date;

    if (typeof date === 'string') {
        validDate = new Date(date);
    } else {
        validDate = date;
    }

    if (isNaN(validDate.getTime())) {
        return '';
    }

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
    };
    return new Intl.DateTimeFormat(locale, options).format(validDate);
}

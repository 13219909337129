import { Box } from "@mui/material"
import Grid from "@mui/material/Grid"
import { useTranslate } from "react-admin";
import CardWithIcon from "../layout/CardWithIcon";
import SpeedIcon from "@mui/icons-material/Speed";
import CardHeader from "../layout/CardHeader";
import CardPlaceholder from "../layout/CardPlaceholder";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { IProfile } from "../model/Profile";
import { Assessment } from "@mui/icons-material";

interface DashboardProps {
    profile: IProfile
}

export const Dashboard = (props: DashboardProps) => {

    const mainStyle = {
        position: 'relative',
        overflow: 'hidden',
        padding: '16px',
        display: 'flex',
        '&:before': {
            position: 'absolute',
            top: '50%',
            left: 0,
            display: 'block',
            content: `''`,
            height: '200%',
            aspectRatio: '1',
            transform: 'translate(+20%, +10%)',
            borderRadius: '50%',
            backgroundColor: 'background.paper',
            opacity: 1,
        },    
    }
    

    const translate = useTranslate();
    const profile = props.profile;

    return (
        <Box display="flex" flexDirection="row" width="100%" height="100%" padding={2} sx={mainStyle}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <CardHeader title={translate("modules.strategical.index")} />
                    { profile?.licensedmodules.filter(module => module.startsWith('strategical.')).length === 0 &&
                        <CardPlaceholder icon={LocalActivityIcon} title={translate("pages.dashboard.nomoduleavailable")}/>
                    }
                </Grid>
                <Grid item xs={4}>
                    <CardHeader title={translate("modules.tactical.index")} />
                    { profile?.licensedmodules.indexOf('tactical.estimation') >= 0 &&
                        <CardWithIcon
                            to="/Estimation"
                            icon={Assessment}
                            title={translate("pages.dashboard.performance")}
                            subtitle={translate("modules.tactical.estimation")}
                        />
                    }
                    { profile?.licensedmodules.filter(module => module.startsWith('tactical.')).length === 0 &&
                        <CardPlaceholder icon={LocalActivityIcon} title={translate("pages.dashboard.nomoduleavailable")}/>
                    }
                </Grid>
                <Grid item xs={4}>
                    <CardHeader title={translate("modules.operational.index")} />
                    { profile?.licensedmodules.indexOf('operational.githubcopilot') >= 0 &&
                        <CardWithIcon
                            to="/Githubcopilotstats"
                            icon={SpeedIcon}
                            title={translate("pages.dashboard.performance")}
                            subtitle={translate("modules.operational.githubcopilotstats")}
                        />
                    }                    
                    { profile?.licensedmodules.filter(module => module.startsWith('operational.')).length === 0 &&
                        <CardPlaceholder icon={LocalActivityIcon} title={translate("pages.dashboard.nomoduleavailable")}/>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}
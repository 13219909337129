import { Store, useLocaleState } from "react-admin";
import { ISettings } from "../settings";
import { useEffect, useRef, useState } from "react";
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import LoadingScreen from "../LoadingScreen";
import Login from "../layout/Login";
import i18nProvider from "./i18nProvider";
import App from "./App";
import { IProfile } from "../model/Profile";

interface AuthWrapperProps {
  settings: ISettings,
  store: Store
}

function AuthWrapper({ settings, store }: AuthWrapperProps){
  const [locale] = useLocaleState();
  const [keycloak, setKeycloak] = useState<Keycloak>();
  const initializingPromise = useRef<Promise<Keycloak>>();
  const login = () => { keycloak?.login({ locale }); };
  const register = () => { keycloak?.register({ locale }) };

  const [profile, setProfile] = useState<IProfile|null>(null);
  
  useEffect(() => {
    const token = store.getItem('keycloak-token');
    const refreshToken = store.getItem('keycloak-refreshToken');
    const idToken = store.getItem('keycloak-idToken');

    const config: KeycloakConfig = { url: settings.url_idp, realm: settings.idp_realm, clientId: settings.idp_clientid};
    const initOptions: KeycloakInitOptions = {
      token, refreshToken, idToken,
      enableLogging: true,
      timeSkew: 60,
      scope: 'openid email profile sessionstate',
      useNonce: false,      
      checkLoginIframe: false
    };
    async function initKeyCloakClient() {
      const keycloakClient = new Keycloak(config);

      keycloakClient.onReady = () => {
        console.dir('Keycloak Ready.');
      }

      keycloakClient.onAuthLogout = () => {
        store.removeItem('keycloak-token');
        store.removeItem('keycloak-refreshToken');
        store.removeItem('keycloak-idToken');
        console.dir('Keycloak Logged out.');
      }

      keycloakClient.onActionUpdate = () => {
        console.dir('Keycloak Action Update.');
      }

      keycloakClient.onAuthRefreshSuccess = () => {
        store.setItem('keycloak-token', keycloakClient.token);
        store.setItem('keycloak-refreshToken', keycloakClient.refreshToken);
        store.setItem('keycloak-idToken', keycloakClient.idToken);
        console.dir('Keycloak Auth Token refreshed.');
      }

      keycloakClient.onAuthSuccess = () => {
        store.setItem('keycloak-token', keycloakClient.token);
        store.setItem('keycloak-refreshToken', keycloakClient.refreshToken);
        store.setItem('keycloak-idToken', keycloakClient.idToken);
        console.dir('Keycloak Auth Success.');
      }

      keycloakClient.onAuthError = () => {
        store.removeItem('keycloak-token');
        store.removeItem('keycloak-refreshToken');
        store.removeItem('keycloak-idToken');
        console.dir('Keycloak Auth Error.');
        window.location.reload();
      }

      keycloakClient.onAuthRefreshError = () => {        
        store.removeItem('keycloak-token');
        store.removeItem('keycloak-refreshToken');
        store.removeItem('keycloak-idToken');
        console.dir('Keycloak Auth Token refresh error.');        
      }

      keycloakClient.onTokenExpired = async () => {
        try{
          console.dir('Keycloak Auth Token expired, refreshing...');
          await keycloakClient.updateToken();
        }catch(e){
          console.dir('Keycloak Error refreshing token: ' + e);
        }
      };

      var result = await keycloakClient.init(initOptions);
      console.dir('Keycloak Client initialized. Authenticated:' + keycloakClient.authenticated + '. Result:' + result + '.');
      
      const jwt_token = store.getItem('keycloak-token')
      const url = settings.url_graphql + `api/me`;
      const meResult = await fetch(url, { headers: { Authorization: `Bearer ${jwt_token}` } });
      const json = await meResult.json();
      setProfile(json);

      return keycloakClient;
    };

    if(!initializingPromise.current){
      initializingPromise.current = initKeyCloakClient();
    }

    initializingPromise.current?.then(keycloakClient => {
      setKeycloak(keycloakClient);
    });
    
  }, [keycloak, settings, store]);
  
  if (!keycloak)
    return <LoadingScreen/>;
  else if( !keycloak!.authenticated || keycloak?.isTokenExpired()){
    console.dir('Not authenticated, showing login...');
    return <Login init={login} register={register} settings={settings} i18n={i18nProvider}  />;
  }else
    if(!profile){
      return <LoadingScreen/>;
    }else{
      console.dir('Authenticated, showing app...'); 
      return (<App settings={settings} keycloak={keycloak} profile={profile} store={store} />);
    }
  }

export default AuthWrapper;
import { useEffect, useState } from "react"
import { Store, useGetList, useTranslate } from "react-admin";
import { Button, Alert, Box, Card, CardContent, CardHeader, Chip, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography, TextField } from "@mui/material";
import { LineChart } from '@mui/x-charts/LineChart';
import { GithubUsage, GithubUsageBreakdown, GithubUsageDay } from "../model/GithubUsage";
import { styled } from '@mui/material/styles';
import { Gauge } from '@mui/x-charts/Gauge';
import { PickerModal } from "mui-daterange-picker-plus";
import type { DateRange } from "mui-daterange-picker-plus";

import TranslateIcon from '@mui/icons-material/Translate';
import CodeIcon from '@mui/icons-material/Code';
import EngineeringIcon from '@mui/icons-material/Engineering';
import IconCard from "../layout/IconCard";
import SavingsIcon from '@mui/icons-material/Savings';
import TrendingUp from "@mui/icons-material/TrendingUp";

import { WordPerLine } from './WordPerLine';
import { ISettings } from "../settings";
import { constants } from '../model/constants';
import LoadingScreen from "../LoadingScreen";
import Systemusersetting from "../model/Systemusersetting";

interface IStatistics{
    activeUsers: number;
    totalEditors: number;
    totalLanguages: number;
    acceptanceRateSuggestion: number;
    acceptanceRateLines: number;
    linesAccepted: number;
    returnOnInvestment: number;
    costsSavings: number;
    costPerDeveloperOverCurrentPeriod:number
}

const defaultEmployeeCost = 79000;                
const investmentPerDeveloper = 25.77;

export const GithubCopilot = ({settings, store}:{settings: ISettings, store:Store}) => { 

    const githubInstallationUrl = `https://github.com/apps/${settings.github_appname}/installations/new`
    const translate = useTranslate();
    const [hasError, setError] = useState<boolean>(false);
    const [isGithubLoading, setGithubLoading] = useState<boolean>(false)
    const [isLines, setIsLines] = useState<boolean>(false);    
    const [isAbsolute, setIsAbsolute] = useState<boolean>(true);
    const [json, setJson] = useState<GithubUsage>();
    const [filteredBreakdown, setFilteredBreakdown] = useState<GithubUsageDay[]>([]);
    const [xAxis, setXAxis] = useState<any>();
    const [series, setSeries] = useState<any>();
    const [languages, setLanguages] = useState<any>();
    const [languagesSelected, setLanguagesSelected] = useState<string[]>([]);
    const [editors, setEditors] = useState<string[]>();
    const [editorsSelected, setEditorsSelected] = useState<string[]>([]);
    const [statistics, setStatistics] = useState<IStatistics>({} as IStatistics);

    const jwt_token = store.getItem('keycloak-token')

    const [isGithubConfigured, setIsGithubConfigured] = useState(true);
    const { data: systemusersetting , isLoading: isSystemUserSettingLoading, error: errorSystemUserSetting } = useGetList<Systemusersetting>('Systemusersetting');
    const [githubOrgEntity, setGithubOrgEntity] = useState<Systemusersetting|undefined>(undefined);
    const [githubNumberOfSeatsEntity, setGithubNumberOfSeatsEntity] = useState<Systemusersetting|undefined>(undefined);

    const getInitialDateRange = ():DateRange => {
        const storedState = localStorage.getItem('githubcopilotdaterange');
        if (storedState) {
            const json = JSON.parse(storedState);
            return { startDate: new Date(json.startDate), endDate: new Date(json.endDate) };
        } else {
            return {
                startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
            };
        }
    }
    const setInitialDateRange = (value:DateRange) => {
        setDateRange(value);
        localStorage.setItem('githubcopilotdaterange', JSON.stringify(value));
    }
    const [dateRange, setDateRange] = useState<DateRange>(getInitialDateRange());
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);  

    const getInitialCostPerDeveloper = ():number => {
        const storedState = localStorage.getItem('githubcopilotcostperdeveloper');
        if (storedState) {
            return JSON.parse(storedState);
        } else {
            return investmentPerDeveloper;
        }
    }

    const [costPerDeveloper, setCostPerDeveloper] = useState<number>(getInitialCostPerDeveloper());
    const setCostPerDeveloperLocalStorage = (value:number) => {
        setCostPerDeveloper(value);
        localStorage.setItem('githubcopilotcostperdeveloper', JSON.stringify(value));
    }

    const getInitialEmployeeCost = ():number => {
        const storedState = localStorage.getItem('githubcopilotemployeecost');
        if (storedState) {
            return JSON.parse(storedState);
        } else {
            return defaultEmployeeCost;
        }
    }    
    const [employeeCost, setEmployeeCost] = useState<number>(getInitialEmployeeCost());
    const setEmployeeCostLocalStorage = (value:number) => {
        setEmployeeCost(value);
        localStorage.setItem('githubcopilotemployeecost', JSON.stringify(value));
    }
  
    useEffect(() => {
        const githubOrgEntity = systemusersetting?.find((setting: Systemusersetting) => setting.name === constants.setting_github_org);
        const githubNumberOfSeatsEntity = systemusersetting?.find((setting: Systemusersetting) => setting.name === constants.setting_github_number_of_seats);
        setGithubOrgEntity(githubOrgEntity);
        setGithubNumberOfSeatsEntity(githubNumberOfSeatsEntity);
        setIsGithubConfigured(githubOrgEntity !== undefined && githubOrgEntity !== undefined);
    }, [systemusersetting]);

    

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(!dateRange.startDate || !dateRange.endDate) return;
    
                setGithubLoading(true);
                const response = await fetch(settings.url_graphql + `api/github/data?startDate=${dateRange.startDate?.toISOString()}&endDate=${dateRange.endDate?.toISOString()}`, {
                    headers: {
                        Authorization: `Bearer ${jwt_token}`
                    },
                });
                const data = await response.json();
                if (!data?.success) {
                    console.error("Failed to fetch Github usage data.");
                    setError(true);
                } else {
                    setJson({ days: data.days as GithubUsageDay[] } as GithubUsage);
                }
            } catch (error) {
                console.error("Failed to fetch Github usage data.", error);
                setError(true);
            } finally {
                setGithubLoading(false);
            }
        };

        fetchData();
    }, [jwt_token, settings, dateRange]);

    useEffect(() => {
        if(!json || !json.days) return;

        json?.days.forEach((day: GithubUsageDay) => {
            day.breakdown?.forEach((breakdown: GithubUsageBreakdown) => {
                const currentLanguage = WordPerLine.find((wordPerLine) => wordPerLine.language === breakdown.language);
                const wordPerLine = currentLanguage ? currentLanguage.words : 4;
                const wordPerMinutes = currentLanguage ? currentLanguage.wpm : 28;
                const numberOfMinutes = breakdown.lines_accepted * wordPerLine / wordPerMinutes;
                const employeeCostPerMinute = employeeCost / (52 * 40 * 60);
                breakdown.cost_savings = numberOfMinutes * employeeCostPerMinute;
            });
        });

        const newLanguages = Array.from(new Set(json?.days.flatMap((day: any) => day.breakdown?.flatMap((language: any) => language.language))));
        setLanguages(newLanguages);
        setLanguagesSelected(newLanguages);
        
        const newEditors = Array.from(new Set(json?.days.flatMap((day: any) => day.breakdown?.flatMap((editor: any) => editor.editor))))
        setEditors(newEditors);
        setEditorsSelected(newEditors);
        
    }, [json, employeeCost, dateRange.startDate, dateRange.endDate, costPerDeveloper]);
    
    useEffect(() => {
        if(!json) return;
        
        const newFilteredBreakdown = json!.days.map((day: GithubUsageDay) => {
            if(!day.breakdown) return day;

            const breakdown = day.breakdown.filter((breakdown: GithubUsageBreakdown) => 
                    languagesSelected.includes(breakdown.language) && editorsSelected.includes(breakdown.editor));
            return {
                ...day,
                total_acceptances_count: breakdown.reduce((acc: number, breakdown: GithubUsageBreakdown) => acc + breakdown.acceptances_count, 0),
                total_suggestions_count: breakdown.reduce((acc: number, breakdown: GithubUsageBreakdown) => acc + breakdown.suggestions_count, 0),
                total_lines_accepted: breakdown.reduce((acc: number, breakdown: GithubUsageBreakdown) => acc + breakdown.lines_accepted, 0),
                total_lines_suggested: breakdown.reduce((acc: number, breakdown: GithubUsageBreakdown) => acc + breakdown.lines_suggested, 0),                
                breakdown
            } as GithubUsageDay;
        });

        setFilteredBreakdown(newFilteredBreakdown);
    }, [languagesSelected, editorsSelected, json]);

    useEffect(() => {

        const seriesSuggestions = [
            {
                label: translate('pages.performance.github.usage.acceptances'),
                data: filteredBreakdown.map((day: any) => day.total_acceptances_count),
                area: true
            },
            {
                label: translate('pages.performance.github.usage.suggestions'),
                data: filteredBreakdown.map((day: any) => day.total_suggestions_count),
                area: true
            }
        ];
        const seriesSuggestionsPercentage = [
            {
                label: translate('pages.performance.github.usage.suggestion_percentage'),
                data: filteredBreakdown.map((day: any) => (Math.round(day.total_acceptances_count / day.total_suggestions_count * 100)) || 0),
                area: true
            }];

        const seriesLines = [
            {
                label: translate('pages.performance.github.usage.lines_accepted'),
                data: filteredBreakdown.map((day: any) => day.total_lines_accepted),
                connectNulls: true,
                area: true
            },
            {
                label: translate('pages.performance.github.usage.lines_suggested'),
                data: filteredBreakdown.map((day: any) => day.total_lines_suggested),
                connectNulls: true,
                area: true
            }
        ];

        const seriesLinesPercentage = [
            {
                label: translate('pages.performance.github.usage.lines_percentage'),
                data: filteredBreakdown.map((day: any) => (Math.round(day.total_lines_accepted / day.total_lines_suggested * 100)) || 0),
                area: true
            }];        

        if(isAbsolute){
            setSeries(isLines ? seriesLines : seriesSuggestions);            
        } else {
            setSeries(isLines ? seriesLinesPercentage : seriesSuggestionsPercentage);
        }

        setXAxis(
            [
                { scaleType: 'point', data: filteredBreakdown.map((day: any) => day.day) }
            ]
        );

        const activeUsers = filteredBreakdown.reduce((acc: number, day: any) =>  day.total_active_users > acc? day.total_active_users:acc, 0);
        const acceptanceRateSuggestion = Math.round(
            filteredBreakdown.filter(b=>b.total_active_users>0).reduce((acc: number, day: any) => acc + day.total_acceptances_count / day.total_suggestions_count * 100, 0) / filteredBreakdown.length);
        const acceptanceRateLines = Math.round(
            filteredBreakdown.filter(b=>b.total_active_users>0).reduce((acc: number, day: any) => acc + day.total_lines_accepted / day.total_lines_suggested * 100, 0) / filteredBreakdown.length);
        
        const costPerDeveloperOverCurrentPeriod = Math.round(costPerDeveloper * (dateRange.endDate!.getTime() - dateRange.startDate!.getTime()) / (1000 * 60 * 60 * 24 * 30)*100)/100;
        const returnOnInvestment = Math.round(
            (
                filteredBreakdown.reduce(
                    (acc: number, day: any) => acc + (day.breakdown?.reduce(
                        (acc: number, breakdown: GithubUsageBreakdown) =>  acc + breakdown.cost_savings, 0) || 0
                    ), 0)
            )
            / costPerDeveloperOverCurrentPeriod * 100 / activeUsers);
        const linesAccepted = Math.round(filteredBreakdown.reduce((acc: number, day: any) => acc + day.total_lines_accepted, 0));

        setStatistics({
                activeUsers,
                totalEditors: editorsSelected.length,
                totalLanguages: languagesSelected.length,
                acceptanceRateSuggestion,
                acceptanceRateLines,
                linesAccepted,
                costsSavings: Math.round(
                    filteredBreakdown.reduce(
                        (acc: number, day: any) => 
                            acc + (day.breakdown?.reduce((acc: number, breakdown: GithubUsageBreakdown) => acc + breakdown.cost_savings, 0) || 0), 
                        0)),
                costPerDeveloperOverCurrentPeriod,
                returnOnInvestment 
            } as IStatistics);

    }, [filteredBreakdown, isLines, isAbsolute, editorsSelected, languagesSelected, translate, dateRange, costPerDeveloper]);

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
      }));

    const boxPadding = {
        display: 'flex',
        justifyContent: 'left',        
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
    };

    if (hasError || errorSystemUserSetting) 
        return <div>An error occured while gathering Github data. Please check your configuration <a href="#/settings/github">here</a>.</div>;

    if (isSystemUserSettingLoading) 
        return <LoadingScreen />;    

    if(!isGithubConfigured){
        return  <Box padding={2} paddingTop={5}>
                    <Alert severity="warning">
                        <Grid container direction="row">
                            <Grid item xs={8}>{translate('pages.performance.github.notconfigured')}</Grid>
                            <Grid item xs={4} alignContent="end">
                                <Button color="secondary" variant="contained" href={githubInstallationUrl}>
                                    {translate('pages.performance.github.install')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Alert>
                </Box>
    }

    if (!xAxis || !series) 
        return <LoadingScreen/>;

    
    const hanndleDateRangechange = (dateRange: DateRange) => {
        setInitialDateRange(dateRange);
        setAnchorEl(null);
    }

    return <>
    <Card sx={{ m: 2 }}>
        <CardContent>
            <Grid container spacing={2} justifyContent="end" alignContent="center">
                <Grid item alignContent="center">
                    <TextField id="employeeCost" label="Employee Cost" value={employeeCost} type="number" onChange={(e)=>setEmployeeCostLocalStorage(Number(e.target.value))} > </TextField>
                </Grid>
                <Grid item alignContent="center">
                    <TextField id="costPerMonth" label="Cost per Developer" value={costPerDeveloper} type="number" onChange={(e)=>setCostPerDeveloperLocalStorage(Number(e.target.value))} > </TextField>
                </Grid>
                <Grid item alignContent="center">
                    {dateRange.startDate && dateRange.endDate && 
                        <Typography variant="body1"><>{dateRange.startDate.toLocaleDateString()} to {dateRange.endDate.toLocaleDateString()}</></Typography>
                    }
                </Grid>
                <Grid item alignContent="center">
                    <Button variant="contained" onClick={(event)=>setAnchorEl(event.currentTarget)}>Select Date Range</Button>
                </Grid>
            </Grid>
        </CardContent>       
    </Card>
    {(!dateRange.startDate || !dateRange.endDate) && 
        <Box padding={2} paddingTop={5}>
            <Alert severity="warning">{translate('pages.performance.github.daterange')}</Alert>
        </Box>
    }
    {!filteredBreakdown.length && dateRange.startDate && dateRange.endDate &&
        <Box padding={2} paddingTop={5}>
            <Alert severity="warning">
                <Grid container direction="row">
                    <Grid item xs={8}>{translate('pages.performance.github.nodata')}</Grid>
                    <Grid item xs={4} alignContent="end">
                        <Button color="secondary" variant="contained" href="#/settings/github">
                            {translate('pages.performance.github.validate')}
                        </Button>
                    </Grid>
                </Grid>
            </Alert>
        </Box>
    }
    <PickerModal
        customProps={{
            onSubmit: hanndleDateRangechange,
            onCloseCallback: ()=>setAnchorEl(null),
        }}
        modalProps={{
            open, anchorEl,
            onClose: () => setAnchorEl(null),
            slotProps: {
                paper: {
                sx: {
                    borderRadius: "16px",
                    boxShadow: "rgba(0, 0, 0, 0.21) 0px 0px 4px",
                },
                },
            },
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            }
        }
    />
    {dateRange.startDate && dateRange.endDate && <>
        <Box padding={2} paddingTop={5}>
            <Grid container direction="row">
                <Grid item container spacing={2} xs={8}>
                    <Grid item xs={4}>
                        <IconCard icon={EngineeringIcon} 
                            title={translate('pages.performance.github.active_developer')} 
                            subtitle={statistics.activeUsers}
                            detail={`${githubNumberOfSeatsEntity!.value} ${translate('pages.performance.github.seats')}, ${statistics.activeUsers} ${translate('pages.performance.github.clients')}`}/>
                    </Grid>
                    <Grid item xs={4}>
                        <IconCard icon={CodeIcon} title={translate('pages.performance.github.ide')} subtitle={statistics.totalEditors} detail={editors?.join(', ')} />
                    </Grid>
                    <Grid item xs={4}>
                        <IconCard icon={TranslateIcon} title={translate('pages.performance.github.language')} subtitle={statistics.totalLanguages} />
                    </Grid>
                    <Grid item xs={4}>
                        <IconCard icon={SavingsIcon} title={translate('pages.performance.github.cost_of_writing')} subtitle={statistics.costsSavings + "$"} detail={ statistics.linesAccepted + translate('pages.performance.github.lines_accepted') } />
                    </Grid>
                    <Grid item xs={4}>
                        <IconCard icon={TrendingUp} title={translate('pages.performance.github.return_on_investment')} subtitle={Math.round(statistics.returnOnInvestment) + "%"} detail={statistics.costPerDeveloperOverCurrentPeriod + translate('pages.performance.github.per_developer')} />
                    </Grid>
                </Grid>
                
                <Grid item textAlign="center" xs={4}>
                    <Typography variant="h6">{translate('pages.performance.github.acceptance_rate')} </Typography>
                    <Grid container flexDirection="row">
                        <Grid item container flexDirection="column" textAlign="center" alignItems="center" xs={6}>
                            <Grid item flexDirection="column">
                                <Gauge width={100} height={100} value={statistics.acceptanceRateSuggestion} startAngle={-110} endAngle={110} text={({ value, valueMax }) => `${value}%`}/>
                                <Typography variant="caption">{translate('pages.performance.github.usage.suggestions')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container flexDirection="column" textAlign="center" alignItems="center" xs={6}>
                            <Grid item flexDirection="column">
                                <Gauge width={100} height={100} value={statistics.acceptanceRateLines}  startAngle={-110} endAngle={110} text={({ value, valueMax }) => `${value}%`}/>
                                <Typography variant="caption">{translate('pages.performance.github.usage.lines')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

        <Card sx={{ m: 2 }}>
            <CardHeader title={translate('pages.performance.github.usage.title')} />
            <CardContent>
                <Box display="flex" flexDirection="row" width="100%" padding={2}>
                    <Grid container spacing={2} xs={3} item>
                        <Box key={1}>
                            <Typography variant="h6">Scope</Typography>
                            <RadioGroup
                                value={isLines ? "1" : "0"}
                                onChange={(event) => setIsLines(event.target.value==='1')}
                            >
                                <FormControlLabel value="0" control={<Radio />} label={translate('pages.performance.github.usage.suggestions')}/>
                                <FormControlLabel value="1" control={<Radio />} label={translate('pages.performance.github.usage.lines')} />
                            </RadioGroup>
                        </Box>

                        <Box key={2}>
                            <Typography variant="h6">Units</Typography>
                            <RadioGroup
                                value={isAbsolute ? "1" : "0"}
                                onChange={(event) => setIsAbsolute(event.target.value==='1')}        
                            >
                                <FormControlLabel value="1" control={<Radio />} label={translate('pages.performance.github.usage.absolute')}/>
                                <FormControlLabel value="0" control={<Radio />} label={translate('pages.performance.github.usage.percentage')} />
                            </RadioGroup>
                        </Box>

                        <Box key={3}>
                            <Typography variant="h6">Languages</Typography>
                            <Box padding={1} sx={boxPadding} component="ul">
                                {languages?.map((language: string) => (
                                    <ListItem key={language}>
                                        <Chip 
                                            label={language} 
                                            size="small" 
                                            color={languagesSelected.includes(language) ? "primary" : undefined}
                                            variant={languagesSelected.includes(language) ? undefined : "outlined"}
                                            onClick={() => {
                                                const updatedLanguagesSelected = languagesSelected.includes(language) 
                                                    ? languagesSelected.filter((selectedLanguage:string) => selectedLanguage !== language)
                                                    : [...languagesSelected, language];
                                                setLanguagesSelected(updatedLanguagesSelected);                                    
                                            }} 
                                        />
                                    </ListItem>
                                ))}
                            </Box>
                        </Box>

                        <Box key={4}>
                            <Typography variant="h6">Editors</Typography>
                            <Box padding={1} sx={boxPadding} component="ul">
                                {editors?.map((editor: string) => (
                                    <ListItem key={editor}>
                                        <Chip 
                                            label={editor} 
                                            size="small" 
                                            color={editorsSelected.includes(editor) ? "primary" : undefined}
                                            variant={editorsSelected.includes(editor) ? undefined : "outlined"}
                                            onClick={() => {
                                                const updatedEditorsSelected = editorsSelected.includes(editor) 
                                                    ? editorsSelected.filter((selectedEditor) => selectedEditor !== editor)
                                                    : [...editorsSelected, editor];
                                                setEditorsSelected(updatedEditorsSelected);
                                            }} 
                                        />
                                    </ListItem>
                                ))}
                            </Box>
                        </Box>
                        <Box key={5} sx={{ flexGrow: 1 }} />
                    </Grid>
                    <Grid container spacing={2}>            
                        <Stack sx={(theme) => ({ width: '100%', height: '350', bgcolor: theme.palette.background.default, borderRadius: 5 })} padding={5}>
                            <LineChart
                                xAxis={xAxis}
                                series={series}                    
                                margin={{ top: 10, bottom: 20 }}
                                skipAnimation
                                colors={['#D4574E', '#62777E', '#DAA520', '#AAB5B8']}
                                
                            />
                        </Stack>
                    </Grid>
                </Box>
                </CardContent>
        </Card>
        </>}
    </>;
}
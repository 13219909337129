import { useState } from 'react';
import { CreateWrapper, EditTabbedWrapper, ListWrapper } from './Wrapper';
import { Datagrid, EditButton, ReferenceField, ReferenceManyCount, ReferenceManyField, TabbedForm, TextField, TextInput, required, useCreate, useDelete, useGetList, useGetRecordId, useRecordContext } from 'react-admin';
import icon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { Systemuserrole } from '../model/Systemuserrole';
import { Systemuseraccessrole } from '../model/Systemuseraccessrole';

const name = "Systemtenant";
const title = "System Tenant";
const recordRepresentation = (record: any) => record.name;

const create = (
    <CreateWrapper title={title}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="emailsuffix" />
    </CreateWrapper>
);

const CreateLicenseButton = () => {
    const navigate = useNavigate();
    const record = useRecordContext();

    const handleClick = () => {
        if (record) {
            navigate('/Systemlicense/create', {
                state: { parentId: record.id },
            });
        }
    };

    return <Button onClick={handleClick}>Add New License</Button>;
};

const CreateAccessButton = () => {
    const navigate = useNavigate();
    const record = useRecordContext();

    const handleClick = () => {
        if (record) {
            navigate('/Systemuseraccess/create', {
                state: { parentId: record.id },
            });
        }
    };

    return <Button onClick={handleClick}>Add New Access</Button>;
};


const AssignRoleButton = () => {
    
    const systemuseraccess_id = useGetRecordId();
    const roleOptions = { filter: { systemuseraccess_id: systemuseraccess_id }, include: { systemuseraccess: true } };
    const { data: systemuseraccessrole , isLoading: isSystemuseraccessrole } = useGetList<Systemuseraccessrole>('Systemuseraccessrole', roleOptions);
    const { data: systemuserrole , isLoading: isSystemuserrole, error: errorSystemuserrole } = useGetList<Systemuserrole>('Systemuserrole');
    const [ createOne ] = useCreate<Systemuseraccessrole>();
    const [ deleteOne ] = useDelete<Systemuseraccessrole>();

    const grantRole = async (role: Systemuserrole) => {
        const data = ({ 
            systemuserrole_id: role.id, 
            systemuseraccess_id: systemuseraccess_id.toString()
        }) as Partial<Systemuseraccessrole>

        await createOne('Systemuseraccessrole', { data: data }, { onSuccess: () => {
            setIsOpen(false);
          }});
    }

    const revokeRole = async (useraccessrole: Systemuseraccessrole) => {
        await deleteOne('Systemuseraccessrole', { id: useraccessrole.id, previousData: useraccessrole }, { onSuccess: () => {
            setIsOpen(false);
        }});
    }

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick}>Assign Role</Button>
            <Dialog open={isOpen}>
                <DialogTitle>Assign Role</DialogTitle>                
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {(isSystemuseraccessrole || isSystemuserrole) ? 'Loading...' :
                            errorSystemuserrole ? 'Error' :
                            systemuserrole!.map((role: any) => {
                                const suar = systemuseraccessrole!.filter((r: Systemuseraccessrole) => r.role_id === role.id);
                                if (suar.length > 0) {
                                    return <Box key={role.id}>
                                            <Button onClick={()=>{revokeRole(suar[0]);}}>Revoke {role.name}</Button>
                                        </Box>;
                                }else{
                                    return <Box key={role.id}>
                                            <Button onClick={()=>{grantRole(role);}}>Grant {role.name}</Button>
                                        </Box>
                                }
                            })}	
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>                
                </DialogActions>
            </Dialog>
        </>
    );
};

const edit = (
    <>
        <EditTabbedWrapper title={title}>
            <TabbedForm.Tab label="Summary">
                <TextInput source="name" validate={[required()]} />
                <TextInput source="emailsuffix" />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Access">
                <ReferenceManyField reference="Systemuseraccess" target="systemtenant_id">
                    <Datagrid>
                        <ReferenceField reference="Systemuser" source="systemuser_id" label="User">
                            <TextField source="idp_givenname" />{' '}
                            <TextField source="idp_familyname" />{' '}
                            (<TextField source="idp_email" />)
                        </ReferenceField>
                        <ReferenceManyCount
                            label="Licenses"
                            reference="Systemuseraccesslicense"
                            target="systemuseraccess_id"
                            link
                        />
                        <ReferenceManyCount
                            label="Roles"
                            reference="Systemuseraccessrole"
                            target="systemuseraccess_id"
                            link
                        />
                        <AssignRoleButton/>
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
                <CreateAccessButton/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Licenses">
                <ReferenceManyField reference="Systemlicense" target="systemtenant_id">
                    <Datagrid>
                        <ReferenceField reference="Systemmodule" source="systemmodule_id" label="Module">
                            <TextField source="name" />{' '}
                        </ReferenceField>
                        <TextField source="quantity" />                        
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
                <CreateLicenseButton/>                
            </TabbedForm.Tab>
        </EditTabbedWrapper>
    </>
);

const list = (
    <ListWrapper title={title}>
        <TextField source="name" validate={[required()]} />
        <TextField source="emailsuffix" />
    </ListWrapper>
);

const options = { label: title };
const tenant = { name, create, edit, list, recordRepresentation, icon, options };
export default tenant;

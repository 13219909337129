import { Button, Chip, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { TaskProps } from './interface';
import Estimationtask from '../model/EstimationTask';
import MenuIcon from '@mui/icons-material/Menu';
import { CreateTaskButton } from './CreateTaskButton';
import EditTaskComponent from './EditTaskComponent';
import { AssignmentTurnedIn, FitnessCenter, HourglassTop, Inventory, MonetizationOn, Verified } from '@mui/icons-material';
import TaskDetailComponent from './TaskDetailComponent';

export const SingleTaskComponent: React.FC<TaskProps> = ({ estimation, task, index, tasks, updateTask, addSubtask, deleteTask, dragHandleProps, isSingleTask, taskTypes, showCosts, showEffort }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isShowingDetail, setIsShowingDetail] = useState(false);
    const localTask = { ...task } as Estimationtask;

    const calculateEffort = (task: Estimationtask): number => {
        if (!task) {
          return 0;
        }
        let total = task.effort??0;
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        for (const child of children) {
          total += calculateEffort(child);
        }
        return total;
      };

    const calculateEffortConfidence = (task: Estimationtask): number => {
        if (!task) {
          return 0;
        }
        let total = task.effortconfident??0;
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        for (const child of children) {
          total += calculateEffortConfidence(child);
        }
        return total;
      };

      const calculateEffortCost = (task: Estimationtask): number => {
        if (!task) {
            return 0;
        }
        let total = (task.effort??0) * (estimation.flat_rate??0);
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        for (const child of children) {
            total += calculateEffortCost(child);
        }
        return total;
    }
    
    const calculateEffortConfidentCost = (task: Estimationtask): number => {
        if (!task) {
            return 0;
        }
        let total = (task.effortconfident??0) * (estimation.flat_rate??0);
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        for (const child of children) {
            total += calculateEffortConfidentCost(child);
        }
        return total;
    }

    const calculateProductCost = (task: Estimationtask): number => {
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        if (!task) { return 0; }
        const cost = (task.cost??0) * (task.quantity??0);        
        var total = (task.hascost?cost:0);
        for (const child of children) {
            total += calculateProductCost(child);
        }
        return total;
    }

    const formatCurrency = (amount:number) => {
        return new Intl.NumberFormat('fr-CA', {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    }

    const taskHasEffort = (task: Estimationtask): boolean => {
        if (!task) { return false; }
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        if (task.haseffort) { return true; }
        for (const child of children) {
            if (taskHasEffort(child)) { return true; }
        }
        return false;
    };

    const taskHasCost = (task: Estimationtask): boolean => {
        if (!task) { return false; }
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        if (task.hascost) { return true; }
        for (const child of children) {
            if (taskHasCost(child)) { return true; }
        }
        return false;
    }


    const hasApprovedParent = (task: Estimationtask): boolean => {
        if (!task) { return false; }
        if (task.approved) { return true; }
        if (!task.parentestimationtask_id) { return false; }
        const parent = tasks[task.parentestimationtask_id];
        return hasApprovedParent(parent);
    };

    const hasCompletedParent = (task: Estimationtask): boolean => {
        if (!task) { return false; }
        if (task.completed) { return true; }
        if (!task.parentestimationtask_id) { return false; }
        const parent = tasks[task.parentestimationtask_id];
        return hasCompletedParent(parent);
    };

    const hasAllApprovedChildren = (task: Estimationtask, skipHead: boolean = true): boolean => {
        if(task.approved) return true;
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        if (children.length === 0) {
          return task.approved;
        }
        return children.every(child => hasAllApprovedChildren(child, false));
    }

    const hasAllCompletedChildren = (task: Estimationtask, skipHead: boolean = true): boolean => {
        if(task.completed) return true;
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        if (children.length === 0) {
          return task.completed;
        }
        return children.every(child => hasAllCompletedChildren(child, false));
    }

    const isParentCompleted = hasCompletedParent(task);
    const isParentApproved = hasApprovedParent(task);  
    const isChildCompleted = hasAllCompletedChildren(task);
    const isChildApproved = hasAllApprovedChildren(task);
    const isCompleted = task.completed || isParentCompleted || isChildCompleted;
    const isApproved = task.approved || isParentApproved || isChildApproved;
    const approvedDrivenByParent = isParentApproved && !task.approved;
    const completedDrivenByParent = isParentCompleted && !task.completed;  
    const approvedDrivenByChild = !isParentApproved && isChildApproved && !task.approved;
    const completedDrivenByChild = !isParentCompleted && isChildCompleted && !task.completed;
    const editable = !task.completed && !task.approved && !isParentApproved && !isParentCompleted;
    const approvable = (task.completed || isChildCompleted ) && !task.approved && !isParentApproved && isChildCompleted;
    const unapprovable = task.approved;
    const completable = !task.completed && !task.approved && !isParentCompleted;
    const uncompletable = task.completed;

    const hasUndefinedWeight = (task: Estimationtask): number => {
        const children = Object.values(tasks).filter(t => t.parentestimationtask_id === task.id);
        if (!task) {
            return 0;
        }
        if (((task.haseffort && task.effort === 0) || (task.hascost && task.cost === 0 && task.quantity ===0)) && children.length === 0) {
            return 1;
        }      
        var total = 0;
        for (const child of children) {
            total += hasUndefinedWeight(child);
        }
        return total;
    };

    const handleEditToggle = () => {
    if (isEditing) {
        updateTask(task.id, localTask);
    }
    setIsEditing(!isEditing);
    };

    const handleUpdate = (id: string, newTaskData: Partial<Estimationtask>) => {
        updateTask(id, newTaskData);
        setIsEditing(false);
    }

    const handleToggleApprove = () => {
        updateTask(task.id, {approved: !task.approved});
    };

    const handleToggleComplete = () => {
        updateTask(task.id, {completed: !task.completed});
    };
    
    return (
        <>
        {isEditing && <EditTaskComponent 
            index={index} 
            tasks={tasks}
            task={task} 
            updateTask={handleUpdate} 
            deleteTask={deleteTask} 
            cancelEdit={()=>setIsEditing(!isEditing)} 
            estimation={task.estimation!}
            />}
        {isShowingDetail && <TaskDetailComponent 
            estimation={estimation}
            tasks={tasks}
            task={task} 
            close={()=>setIsShowingDetail(false)} />}

        <Grid item container direction="column" spacing={2}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ height: "45px" }}
            justifyContent={isSingleTask?"":"space-between"}
            >
            <Grid item textAlign="left">
                <Stack direction="row" spacing={1}>
                    {!isCompleted && !isApproved && <HourglassTop color='disabled' />}
                    {isCompleted && !isApproved && <AssignmentTurnedIn color= {(completedDrivenByParent||completedDrivenByChild)?'disabled':'info'} />}
                    {isApproved && <Verified color={(approvedDrivenByParent||approvedDrivenByChild)?'disabled':'success'} />}
                    <Link onClick={()=>setIsShowingDetail(true)} underline='hover'>                    
                        <Typography variant="body1" color={(!task.name || task.name.length===0)?'GrayText':'WindowText'}>
                            {(!task.name || task.name.length===0)? 'New ' + task.estimationtasktype.name:task.name}
                        </Typography>
                    </Link>
                    {(showEffort || showCosts) && taskHasEffort(task) && 
                        <Chip 
                            icon={<FitnessCenter/>} color='default' size='small'
                            label={ 
                                ((showCosts?formatCurrency(calculateEffortCost(task)):'') + 
                                ((showCosts && showEffort)?' (':'')+
                                (showEffort?(calculateEffort(task).toFixed(2) + "/" + calculateEffortConfidence(task).toFixed(2)):'') +
                                ((showCosts && showEffort)?')' : ''))
                            } />
                    }
                    {showCosts && taskHasCost(task) && 
                        <Chip 
                            icon={<Inventory/>} color='default' size='small'
                            label={formatCurrency(calculateProductCost(task))}/>
                    }
                    {showCosts &&
                        <Chip 
                                icon={<MonetizationOn />} color={isCompleted?isApproved?'success':'info':'default'} size='small'
                                label={formatCurrency(calculateEffortConfidentCost(task)+calculateProductCost(task))}/>
                    }
                    {hasUndefinedWeight(task)!==0 && <Chip label={hasUndefinedWeight(task)+' unestimated'} color='warning' size='small'  /> }
                </Stack>
            </Grid>
            <Grid item textAlign='right'>
                {/* {(task.effort && task.effort>0)?<Chip label='E' color='secondary' size='small' />:<></>} */}
                {task.description && <Chip label='D' color='secondary' size='small' />}
                {task.assumptions && <Chip label='A' color='secondary' size='small' />}
                <Chip label={task.estimationtasktype.name} color="secondary" size='small'/>
                
                {isHovered && (<>
                    {/* <Button onClick={handleDelete} color='warning'>Delete</Button> */}

                    {completable && <Button onClick={handleToggleComplete} color='success'>Mark Complete</Button>}                    
                    {editable && <Button onClick={handleEditToggle} size='small' color='secondary'>Edit</Button>}
                    {editable && <CreateTaskButton parentId={task.id} clickHandler={addSubtask} parentTaskType={task.estimationtasktype_id} taskTypes={taskTypes} />}
                    {uncompletable && <Button onClick={handleToggleComplete} color='warning'>Unmark Completed</Button>}                    
                    {approvable && <Button onClick={handleToggleApprove} color='success'>Approve</Button>}
                    {unapprovable && <Button onClick={handleToggleApprove} color='warning'>Unapprove</Button>}
                </>)}
                {dragHandleProps && 
                    <IconButton {...dragHandleProps} size='small' color='secondary'><MenuIcon /></IconButton>
                }
            </Grid>
        </Grid>
    </>
    );
}
import { useEffect, useState } from "react";
import { ISettings, fetchSettings } from "../settings";
import LoadingScreen from "../LoadingScreen";
import AuthWrapper from "./AuthWrapper";
import GithubConfiguration from "../performance/GithubConfiguration";
import { localStorageStore } from "react-admin";

function ConfigWrapper() {
  const [settings, setSettings] = useState<ISettings>();   
  const store = localStorageStore(undefined, 'PrimeFactor');
  store.setup();
  
  useEffect(() => {
    const getSettings = async () => {
      setSettings(await fetchSettings());
    };
  
    if(!settings)
      getSettings();
  }, [settings]);

  const isGitHubInstallation = window.location.href.indexOf('?code=') !== -1;

  return (
    <>
      {!settings && <LoadingScreen />}
      {settings && isGitHubInstallation && <GithubConfiguration settings={settings} store={store}/>}
      {settings && !isGitHubInstallation && <AuthWrapper settings={settings} store={store}/>}
    </>

  );  
}

export default ConfigWrapper;

  
import { AuthProvider } from 'react-admin';
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { jwtDecode } from 'jwt-decode';
import { fetchUtils } from 'react-admin';

export type PermissionsFunction = (decoded: KeycloakTokenParsed) => any;


export const keycloakAuthProvider = (
    client: Keycloak,
    options: {
        onPermissions?: PermissionsFunction;
        loginRedirectUri?: string;
        logoutRedirectUri?: string;
    } = {}
): AuthProvider => ({
    async login() {
        return client.login({
            redirectUri: options.loginRedirectUri ?? window.location.origin,
        });
    },
    async logout() {
        return client.logout({
            redirectUri: options.logoutRedirectUri ?? window.location.origin,
        });
    },
    async checkError() {
        return Promise.resolve();
    },
    async checkAuth() {
        return client.authenticated && client.token
            ? Promise.resolve()
            : Promise.reject('Failed to obtain access token.');
    },
    async getPermissions() {
        if (!client.token) {
            return Promise.resolve(false);
        }
        const decoded = jwtDecode<KeycloakTokenParsed>(client.token);
        return Promise.resolve(
            options.onPermissions ? options.onPermissions(decoded) : decoded
        );
    },
    async getIdentity() {
        if (client.token) {
            const decoded = jwtDecode<KeycloakTokenParsed>(client.token);
            const id = decoded.sub || '';
            var fullName = decoded.given_name + " " + decoded.family_name;
            if(!decoded.given_name && !decoded.family_name)  fullName = decoded.preferred_username;
            return Promise.resolve({ id, fullName });
        }
        return Promise.reject('Failed to get identity.');
    },
});


export const httpClient = (keycloak: Keycloak) => (
    url: any,
    options: fetchUtils.Options | undefined
) => {
    const requestHeaders = getKeycloakHeaders(keycloak.token || null, options);
    return fetchUtils.fetchJson(url, {
        ...options,
        headers: requestHeaders,
    });
};

export const getKeycloakHeaders = (
    token: string | null,
    options: fetchUtils.Options | undefined
): Headers => {
    const headers = ((options && options.headers) ||
        new Headers({
            Accept: 'application/json',
        })) as Headers;
    if (token) {
        headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
};
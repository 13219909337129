import { Layout, LayoutProps } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { IProfile } from '../model/Profile';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

interface LayoutRootProps extends LayoutProps {
    profile: IProfile
}

const LayoutRoot = (props: LayoutRootProps) => {

    const location = useLocation();
  
    useEffect(() => {
        if((window as any)._paq) return;
      const matomoUrl = 'https://analytics.evoluable.net';
      const siteId = '7';
  
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = `${matomoUrl}/matomo.js`;
      document.body.appendChild(script);
  
      (window as any)._paq = (window as any)._paq || [];
      (window as any)._paq.push(['trackPageView']);
      (window as any)._paq.push(['enableLinkTracking']);
      (window as any)._paq.push(['setTrackerUrl', `${matomoUrl}/matomo.php`]);
      (window as any)._paq.push(['setSiteId', siteId]);

      console.warn('MatomoInitializer');
    }, []);

    useEffect(() => {
        (window as any)._paq.push(['setCustomUrl', location.pathname]);
        (window as any)._paq.push(['trackPageView']);
    }, [location]);
  
    return <Layout {...props} appBar={AppBar} menu={(iprops) => <Menu {...iprops} profile={props.profile} />} />;
    
};

export default LayoutRoot;

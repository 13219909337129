import { CreateWrapper, EditWrapper, ListWrapper } from './Wrapper';
import { NumberField, NumberInput, ReferenceField, ReferenceInput } from 'react-admin';
import icon from '@mui/icons-material/VerifiedUser';

const name = "Systemlicense";
const title = "System License";
const recordRepresentation = (record: any) => record.systemmodule_id;

const create = (
    <CreateWrapper title={title}>
        <ReferenceInput reference="Systemtenant" source="systemtenant_id" label="Tenant"/>
        <ReferenceInput reference="Systemmodule" source="systemmodule_id" label="Module"/>
        <NumberInput source="quantity" label="Quantity"/>
    </CreateWrapper>
);

const edit = (
    <EditWrapper title={title}>
        <ReferenceInput reference="Systemtenant" source="systemtenant_id" label="Tenant"/>
        <ReferenceInput reference="Systemmodule" source="systemmodule_id" label="Module"/>
        <NumberInput source="quantity" label="Quantity"/>        
    </EditWrapper>
);

const list = (
    <ListWrapper title={title}>
        <ReferenceField reference="Systemtenant" source="systemtenant_id" label="Tenant"/>        
        <ReferenceField reference="Systemmodule" source="systemmodule_id" label="Module"/>
        <NumberField source="quantity" label="Quantity"/>        
    </ListWrapper>
);

const options = { label: title };
const license = { name, create, edit, list, recordRepresentation, icon, options };
export default license;

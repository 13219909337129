import React from "react"
import { Box,Typography } from "@mui/material";
import { ISettings } from "../settings";
import { Create, SimpleForm, Store, TextInput, useTranslate } from "react-admin";
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';

interface IData {
    scope: string;
    title: string;
}

export const RequestAccess = ({settings, store}:{settings: ISettings, store:Store}) => {
    
    const translate = useTranslate(); 
    const jwt_token = store.getItem('keycloak-token')        
    const [submitting, setSubmitting] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false); 
    const [error, setError] = React.useState(false);

    const [data, setData] = React.useState({} as IData);

    React.useEffect(() => {
        
        const url = new URL(window.location.href);
        const hashSegments = url.hash.split("?");
        if (hashSegments.length < 2) return;

        const params = new URLSearchParams(hashSegments[1]);
        const scope = params.get('scope');
        var title = translate('modules.' + scope );
        setData({ scope, title} as IData);
    }, [translate]);

    const handleFormSubmit = async 	(data: any, e?: any)=> {
        e.preventDefault();
        try {
            setSubmitting(true);
            const response = await fetch(settings.url_graphql + `api/notification/send`,
                { 
                    method: 'POST', 
                    headers: { 
                        Authorization: jwt_token ? `Bearer ${jwt_token}` : '', 
                        'Content-Type': 'application/json' 
                    },
                    body: JSON.stringify({
                        "title": data.title,
                        "message": data.message
                    })
                }
            );
            if (response.ok) {
                setSubmitted(true);
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        }
    };

    return (
        <Box padding={2} paddingTop={5}>
            {!error && submitted && (<Alert icon={<CheckCircleIcon/>}>{translate("pages.requestaccess.thankyou")}</Alert>)}
            {!error && !submitted && submitting && (<Alert icon={<PendingIcon/>} severity="info" >{translate("pages.requestaccess.submitting")}</Alert>)}
            {error && (<Alert icon={<ErrorIcon />} severity="error" >{translate("pages.requestaccess.error")}</Alert>)}
            {!submitted && !submitting && !error && (<>
                <Typography variant="h4">{translate('pages.requestaccess.title')} {data.title}</Typography>
                <Typography variant="body1">{translate('pages.requestaccess.description')}</Typography>
                <Create resource="Access">
                    <SimpleForm onSubmit={handleFormSubmit} defaultValues={data} >
                        <TextInput source={"message"} title={translate("pages.requestaccess.messagecontent")} style={{ height: '300px', width: '500px' }} multiline={true} rows={10} isRequired={true}/>
                    </SimpleForm>
                </Create>
            </>)}
        </Box>
    );
};
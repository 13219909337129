import { DataProvider, ResourceCallbacks } from "react-admin";

const convertFileToBase64 = (file: { rawFile: Blob; }) =>
new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
});

const base64ConverterHandlers: ResourceCallbacks[] = [
  {
    resource: '*',
    beforeUpdate: async (params: any, dataProvider: DataProvider) => {
      if (params.data.logo?.rawFile) {
        const dataUrl: string = await convertFileToBase64(params.data.logo) as string;
        const logo = dataUrl.toString().split(",")[1];
        const paramsToReturn = {
          ...params,
          data: {
            ...params.data,
            logo
          }
        };
        return paramsToReturn;
      } else {
        return params;
      }
    }
  },
  {
    resource: '*',
    afterGetOne: async (params: any, dataProvider: DataProvider) => {
      if (params.data.logo) {
        var blob = new Blob([new Uint8Array(params.data.logo.data)], { type: 'application/octet-stream' });

        const paramsToReturn = {
          ...params,
          data: {
            ...params.data,
            logo: [{
              title: 'Logo',
              src: window.URL.createObjectURL(blob)
            }]
          }
        };
        return paramsToReturn;
      }
      return params;
    }
  }
];

export default base64ConverterHandlers;

import { CreateWrapper, EditWrapper, ListWrapper } from './Wrapper';
import { Button, TextField, TextInput, required, useRecordContext } from 'react-admin';
import icon from '@mui/icons-material/AccountCircle';

const name = "Systemuser";
const title = "System User";
const recordRepresentation = (record: any) => record.idp_email;

const create = (
    <CreateWrapper title={title}>
        <TextInput source="idp_sid" validate={[required()]} />
        <TextInput source="idp_email" validate={[required()]} />
        <TextInput source="idp_givenname" />
        <TextInput source="idp_familyname" />
    </CreateWrapper>
);

const edit = (
    <EditWrapper title={title}>
        <TextInput source="idp_sid" validate={[required()]} />
        <TextInput source="idp_email" validate={[required()]} />
        <TextInput source="idp_givenname" />
        <TextInput source="idp_familyname" />
    </EditWrapper>
);

const ImpersonateButton = () => {
    const record = useRecordContext();
    return <Button href={"#/sysadmin/impersonate?email=" + record.idp_email} label='Impersonate' ></Button>
  }

const list = (
    <ListWrapper title={title}>
        <TextField source="idp_sid" label="Sid" />
        <TextField source="idp_email" label="Email"/>
        <TextField source="idp_givenname" label="Given Name"/>
        <TextField source="idp_familyname" label="Family Name" />
        <ImpersonateButton/>
    </ListWrapper>
);

const options = { label: title };
const user = { name, create, edit, list, recordRepresentation, icon, options };
export default user;

import { Box, Typography } from "@mui/material"
import { Create, Loading, SaveButton, SimpleForm, Store, TextInput, Toolbar, useNotify, useTranslate } from "react-admin";
import { ISettings } from "../settings";

export const UserImpersonate = ({ settings, store }: { settings: ISettings, store:Store }) => {
    const notify = useNotify();
    const translate = useTranslate();
    var jwt_token = store.getItem('keycloak-token')

    const impersonate = async (email: string) => {
        const url = settings.url_graphql + `api/users/impersonate?email=${email}`;
        const result = await fetch(url, { headers: { Authorization: `Bearer ${jwt_token}` } });
        const json = await result.json();

        if(result.status !== 200){
            notify(translate('pages.settings.impersonation.error'), { type: 'error' });
            return;
        }
        
        if(json.code === "ERR_ASSERTION") {
            notify(translate('pages.settings.impersonation.error'), { type: 'error' });
        }else{
            store.setItem('keycloak-token', json.access_token);
            store.setItem('keycloak-refreshToken', json.refresh_token);
            store.setItem('keycloak-idToken', json.id_token);

            window.location.href = '/#';
            window.location.reload();
        }
    }


    var email;
    const url = new URL(window.location.href);
    const hashSegments = url.hash.split("?");
    if (hashSegments.length > 1){
        const params = new URLSearchParams(hashSegments[1]);
        email = params.get("email")!;
        if(email){
            impersonate(email);
            return <Loading />
        }
    }    

    const handleFormSubmit = async 	(data: any, e?: any) => impersonate(data.email);

    const toolbar = <Toolbar>
                        <SaveButton label="pages.settings.impersonation.submit" />
                    </Toolbar>;

    return (
        <div>
            <Box padding={2} paddingTop={5}>
                <Typography variant="h4">{translate('pages.settings.impersonation.title')}</Typography>
                <Typography variant="body1">{translate('pages.settings.impersonation.description')}</Typography>
                <Create resource="Impersonation" title={translate('pages.settings.impersonation.title')} >
                    <SimpleForm onSubmit={handleFormSubmit} toolbar={toolbar}>
                        <TextInput source={"email"} title={translate("pages.settings.impersonation.email")} required/>
                    </SimpleForm>
                </Create>
            </Box>
        </div>
    )
}
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ConfigWrapper from './App/ConfigWrapper';

if(window.location.pathname.length !== 1){
  window.location.href = '/#' + window.location.pathname + window.location.search;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<ConfigWrapper />);

reportWebVitals();

import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { TaskListComponent } from './TaskListComponent';
import Estimationtask from '../model/EstimationTask';
import { BooleanInput, Datagrid, EditButton, NumberInput, ReferenceField, ReferenceManyField, Store, TabbedForm, TextField, TextInput, useCreate, useDelete, useGetList, useGetRecordId, useRecordContext, useUpdate } from 'react-admin';
import { Button, FormControlLabel, FormGroup, Grid, Stack, Switch } from '@mui/material';
import { EditTabbedWrapper } from '../system/Wrapper';
import { useNavigate } from 'react-router';
import { CreateTaskButton } from './CreateTaskButton';
import Estimationtasktype from '../model/Estimationtasktype';
import Estimation from '../model/Estimation';
import { ISettings } from '../settings';

interface EditProps {
  settings: ISettings;
  store: Store
}

const Edit: React.FC<EditProps> = (props) => {

  const [create] = useCreate<Estimationtask, Error>();
  const [tasks, setTasks] = useState<{ [id: string]: Estimationtask }>({});
  
  const recordId = useGetRecordId();
  const { data: estimations, isLoading: isEstimationLoading } = useGetList<Estimationtask>('Estimation', { filter: { id: recordId.toString() } });
  const [estimation, setEstimation] = useState<Estimation>();

  const options = { pagination: { page: 1, perPage:1000 }, filter: { estimation_id: recordId.toString() } };
  const { data: estimationTask, isLoading: isEstimationTaskLoading } = useGetList<Estimationtask>('Estimationtask', options);
  const [ deleteOne ] = useDelete<Estimationtask>();
  const [ update ] = useUpdate<Estimationtask>();

  const typesOptions = { filter: { estimation_id: recordId.toString() } };
  const { data: estimationTaskType } = useGetList<Estimationtasktype>('Estimationtasktype', typesOptions);

  const [showCosts, setShowCosts] = useState<boolean>(false);
  const [showEffort, setShowEffort] = useState<boolean>(false);

  useEffect(() => {
    if (estimations) {
      setEstimation(estimations[0]);
    }
  }, [estimations]);

  useEffect(() => {
    if (estimationTask && estimationTaskType) {
      const newTasks = estimationTask.reduce((acc, task) => {
        task.estimationtasktype = estimationTaskType.find(type => type.id === task.estimationtasktype_id)!;
        task.estimation = estimation!;
        acc[task.id] = task;
        return acc;
      }, {} as { [id: string]: Estimationtask });
      setTasks(newTasks);
    }
  }, [estimationTask,estimationTaskType, estimation]);

  const updateTask = (id: string, newTaskData: Partial<Estimationtask>) => {
    const data = { ...newTaskData } as Partial<Estimationtask>;
    delete data.parentestimationtask_id;
    delete data.parentestimationtask;
    delete data.estimationtask;
    delete data.estimationtasktype;
    delete data.estimation;
    delete data.estimation_id;
    delete data.approvedby;
    delete data.approvedby_id;
    update('Estimationtask', { id, data }, { onSuccess: (newData) => {
      const newTasks = { ...tasks };
      newTasks[id] = { ...newTasks[id], ...newData };
      setTasks(newTasks);
      
    }});
  };

  const addSubtask = async (parentId: string | null, taskType: Estimationtasktype) => {
    const nextOrderForParent =  Object.values(tasks).filter(task => task.parentestimationtask_id === parentId).reduce((acc, task) => Math.max(acc, task.order), 0)+1;

    const data = ({ 
      name: '', 
      description: '', 
      effortrealistic: 0, 
      effortoptimistic: 0,
      effortpessimistic: 0,
      parentestimationtask_id: parentId,
      estimation_id: recordId.toString(),
      estimationtasktype_id: taskType.id,
      order: nextOrderForParent
    }) as Partial<Estimationtask>;

    await create('Estimationtask', { data }, { onSuccess: (newTask) => {
      const newTasks = {
        ...tasks,
        [newTask.id]: newTask,
      };
      setTasks(newTasks);
    
    }});

  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      console.warn('No change');
      return;
    }
    const sourceTask = tasks[result.draggableId];
    delete tasks[result.draggableId];

    const destinationId = destination.droppableId === 'root' ? null : destination.droppableId;

    const newTasks = { ...tasks };
    const destinationTasks = Object.values(newTasks).filter(task => task.parentestimationtask_id === destinationId);
    sourceTask.parentestimationtask_id = destinationId;
    destinationTasks.sort((a, b) => a.order - b.order);
    destinationTasks.splice(destination.index, 0, sourceTask);
    for (let i = 0; i < destinationTasks.length; i++) {
      const task = destinationTasks[i];
      const data = { ...task, order: i } as Estimationtask;
      newTasks[task.id] = data;
      await update('Estimationtask', { id: task.id, data: { parentestimationtask_id: data.parentestimationtask_id, order: i  } });
    }
    Object.values(newTasks).sort((a, b) => a.order - b.order);        
    setTasks(newTasks);
  };

  const deleteTask = (id: string) => {
    deleteOne('Estimationtask', { id, previousData: tasks[id] }, { onSuccess: () => {
      const newTasks = { ...tasks };
      delete newTasks[id];
      setTasks(newTasks);
    }});
  };

  const CustomCreateButton = () => {
      const navigate = useNavigate();
      const record = useRecordContext();

      const handleClick = () => {
          if (record) {
              navigate('/Estimationtasktype/create', {
                  state: { parentId: record.id },
              });
          }
      };

      return <Button onClick={handleClick}>Create Root Task Type</Button>;
  };

  const exportToExcel = () => {
    const jwt_token = props.store.getItem('keycloak-token')

    const url = props.settings.url_graphql + `api/estimation/excel?id=${recordId}`;
    const downloadFile = async (url: string) => {
      try {
        const response = await fetch(url, { headers: { Authorization: `Bearer ${jwt_token}` }});
        const blob = await response.blob();
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        const date = new Date().toISOString().slice(0,10).replace(/-/g,"");
        link.setAttribute('download', 'PrimeFactor-' + estimation?.name + '-'+ date + '.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    downloadFile(url);
  }

  if (isEstimationTaskLoading || isEstimationLoading) return <p>Loading...</p>;

  return <>
    <EditTabbedWrapper title={estimation?.name??''} aside={false}>
        <TabbedForm.Tab label="General">
            <TextInput source="name" label="Name"/>
            <TextInput source="description" label="Description"
                  sx={{width:400}}
                  multiline
                  rows={4}
            />            
            <TextInput type="number" source="target_confidence_level" label="Target Confidence Level"/>
            <BooleanInput source="useflatrate" label="Use flat rate"/>
            <NumberInput type="number" source="flat_rate" label="Flat Rate"/>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Tasks">
            <Grid container direction="row" spacing={2} justifyContent="space-between">
              <Grid item>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2}>
                  <FormGroup row>
                    <FormControlLabel control={
                      <Switch                  
                        checked={showCosts}
                        onChange={()=>setShowCosts(!showCosts)}
                      />} 
                      label="Show Cost" />
                    <FormControlLabel control={
                      <Switch
                        checked={showEffort}
                        onChange={()=>setShowEffort(!showEffort)}
                      />} 
                      label="Show Effort" />
                  </FormGroup>                
                  <Button onClick={exportToExcel}>Export to Excel</Button>
                  <CreateTaskButton parentId={null} parentTaskType={null} clickHandler={addSubtask} taskTypes={estimationTaskType!} />            
                </Stack>
              </Grid>
            </Grid>
            <DragDropContext onDragEnd={onDragEnd}>
                <TaskListComponent 
                    estimation={estimation!} task={null} 
                    addSubtask={addSubtask} updateTask={updateTask} deleteTask={deleteTask} 
                    index={0} tasks={tasks} taskTypes={estimationTaskType!} showCosts={showCosts} showEffort={showEffort} />
            </DragDropContext>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Task Types" path='tasktype'>
            <ReferenceManyField reference="Estimationtasktype" target="estimation_id" label="Task Types">
                <Datagrid>
                    <TextField source="name" label="Name"/>
                    <ReferenceField source="parentestimationtasktype_id" reference="Estimationtasktype" label="Parent Task"/>
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
            <CustomCreateButton/>
        </TabbedForm.Tab>
    </EditTabbedWrapper>
  </>;
};

export default Edit;

import * as React from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useTranslate, useGetList, useDelete, Loading, SimpleForm, Create, Toolbar, Store } from 'react-admin';
import GitHubIcon from '@mui/icons-material/GitHub';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect } from 'react';
import { constants } from '../model/constants';
import { ISettings } from '../settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Systemusersetting from '../model/Systemusersetting';

export const GithubSettings = ({settings, store}:{settings: ISettings, store:Store}) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const translate = useTranslate();
    const jwt_token = store.getItem('keycloak-token')            

    const [githubOrgEntity, setGithubOrgEntity] = React.useState<Systemusersetting>();
    const [githubInstallationEntity, setGithubInstallationEntity] = React.useState<Systemusersetting>();
    const [numberOfUsers, setNumberOfUsers] = React.useState<Systemusersetting>();

    const { data: systemusersetting , isLoading: isSystemUserSettingLoading, error: errorSystemUserSetting } = useGetList('Systemusersetting');
    const [ deleteOne, { isLoading: isDeleteLoading,  error }] = useDelete();
    const [ updateSucceed, setUpdateSucceed ] = React.useState(false);

    useEffect(() => {  

        systemusersetting?.forEach((setting: Systemusersetting) => {
            if (setting.name === constants.setting_github_org) {
                setGithubOrgEntity(setting);
            }
            if (setting.name === constants.setting_github_installation_id) {
                setGithubInstallationEntity(setting);
            }
            if (setting.name === constants.setting_github_number_of_seats) {
                setNumberOfUsers(setting);
            }
        });

        setIsLoading(false);

    }, [systemusersetting]);

    const disconnectFromGithub = () => {
        if(githubOrgEntity)             deleteOne('Systemusersetting', { id: githubOrgEntity.id, previousData: githubOrgEntity});
        if(githubInstallationEntity)    deleteOne('Systemusersetting', { id: githubInstallationEntity.id, previousData: githubInstallationEntity});    
        if(numberOfUsers)               deleteOne('Systemusersetting', { id: numberOfUsers.id, previousData: numberOfUsers});
        if(githubOrgEntity && githubInstallationEntity){
            window.location.href = `https://github.com/organizations/${githubOrgEntity.value}/settings/installations/${githubInstallationEntity.value}`;
        }
        return false;
    }

    const refreshData = () => {
        if(githubInstallationEntity)
            fetch(settings.url_graphql + `api/github/run?installation_id=${githubInstallationEntity.value}`,
                { headers: { Authorization: jwt_token ? `Bearer ${jwt_token}` : '' } }
            )
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setUpdateSucceed(true);
                }
            });
    }
       
    if (isLoading || isSystemUserSettingLoading || isDeleteLoading) return <Loading/>;
    if (errorSystemUserSetting || error) return <div>{translate('pages.settings.githubsettings.error')}</div>;
    
    const toolbar = <Toolbar>
            {!githubInstallationEntity?.id &&<Button
                variant="contained"
                href={`https://github.com/apps/${settings.github_appname}/installations/new`}
                style={{margin: '5px'}}
                startIcon={<GitHubIcon />}
            >
                {translate('pages.settings.githubsettings.install')}
            </Button>}
            {githubInstallationEntity?.id && <Button
                variant="contained"
                href=""
                style={{margin: '5px'}}
                startIcon={<RefreshIcon />}
                color='primary'
                onClick={refreshData}>
                {translate('pages.settings.githubsettings.refresh')}
                </Button>
            }
            {githubInstallationEntity?.id && <Button
                variant="contained"
                href=""
                style={{margin: '5px'}}
                startIcon={<LogoutIcon />}
                color='error'
                onClick={disconnectFromGithub}>
                {translate('pages.settings.githubsettings.uninstall')}
                </Button>
            }

        </Toolbar>;

    return  <Box sx={{p:2}}>    
            
                { numberOfUsers && numberOfUsers.value === "0" &&
                    <Alert severity="warning">
                        {translate('pages.settings.githubsettings.noseats')}
                    </Alert>
                }

                <Typography variant="h5" component="h1" gutterBottom>
                    {translate('pages.settings.githubsettings.title')}
                </Typography>
                {updateSucceed &&  <Alert severity="success">{translate('pages.settings.githubsettings.updatesuccedded')}</Alert>}
                <Create resource="Systemusersetting" title={translate('pages.settings.title')}>
                    <SimpleForm toolbar={toolbar}>
                        <Typography variant="body1" component="p" gutterBottom>
                            {!githubInstallationEntity?.id && translate('pages.settings.githubsettings.description_notinstalled')}
                            {githubInstallationEntity?.id && translate('pages.settings.githubsettings.description_installed')}
                        </Typography>
                        {githubOrgEntity && 
                            <Typography variant="body1" component="p" gutterBottom>
                                {translate('pages.settings.githubsettings.organization')}: <b>{githubOrgEntity?.value}</b>
                            </Typography>}
                        {numberOfUsers && 
                            <Typography variant="body1" component="p" gutterBottom>
                                {translate('pages.settings.githubsettings.numberofusers')}: <b>{numberOfUsers.value}</b>
                            </Typography>}
                        {!githubInstallationEntity?.id ? <div>{translate('pages.settings.githubsettings.no_organization')}</div>:''}
                    </SimpleForm>
                </Create>
            
            </Box>;
};



// const section = <Card
//     sx={{
//         background: theme =>
//             `linear-gradient(45deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.light} 50%, ${theme.palette.primary.dark} 100%)`,
//         color: theme => theme.palette.primary.contrastText,
//         padding: '20px',
//         marginTop: 2,
//         marginBottom: '1em',
//     }}
//     >
//     <Box display="flex">
//         <Box flex="1">
//             <Typography variant="h5" component="h2" gutterBottom>
//             </Typography>
//             <Box maxWidth="40em">
                
//             </Box>
//             <CardActions
//                     sx={{
//                         padding: { xs: 0, xl: null },
//                         flexWrap: { xs: 'wrap', xl: null },
//                         '& a': {
//                             marginTop: { xs: '1em', xl: null },
//                             marginLeft: { xs: '0!important', xl: null },
//                             marginRight: { xs: '1em', xl: null },
//                         },
//                     }}
//                 >                    
//             </CardActions>
//         </Box>            
//     </Box>
//     </Card>;
import { CreateWrapper, EditWrapper, ListWrapper } from './Wrapper';
import { TextField, TextInput, required } from 'react-admin';
import icon from '@mui/icons-material/Widgets';

const name = "Systemmodule";
const title = "System Modules";
const recordRepresentation = (record: any) => record.name;

const create = (
    <CreateWrapper title={title}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="description" />
    </CreateWrapper>
);

const edit = (
    <EditWrapper title={title}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="description" />
    </EditWrapper>
);

const list = (
    <ListWrapper title={title}>
        <TextField source="name" />
        <TextField source="description" />
    </ListWrapper>
);

const options = { label: title };
const module = { name, create, edit, list, recordRepresentation, icon, options };
export default module;

import * as React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
    title?: string;
    subtitle?: React.ReactNode;    
    sx?: any;
}

const boxActiveStyle = {
    position: 'relative',
    overflow: 'hidden',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .icon': {
        color: 'secondary.main',
    },
    '&:before': {
        position: 'absolute',
        top: '50%',
        left: 0,
        display: 'block',
        content: `''`,
        height: '500%',
        aspectRatio: '1',
        transform: 'translate(+60%, -60%)',
        borderRadius: '50%',
        backgroundColor: 'primary.main',
        opacity: 0.05,
    },    
}

const CardHeader = ({ title, subtitle, sx }: Props) => (

    
        <Box sx={boxActiveStyle}>
            <Box textAlign="right">
                <Typography color="textSecondary" variant='h5'>{title}</Typography>                
            </Box>
        </Box>
    
);

export default CardHeader;
import { ThemeOptions } from "@mui/material";

export const darkTheme: ThemeOptions = {
    palette: {
      mode: 'dark',
      primary: {
        main: '#295a6c',
      },
      secondary: {
        main: '#daa520',
      },
      background: {
        default: '#101e23',
        paper: 'rgba(16,30,35,0.36)',
      },
    },
  };
  
export const lightTheme: ThemeOptions = {
palette: {
    mode: 'light',
    primary: {
      main: '#295a6c',
    },
    secondary: {
      main: '#587C90',
    },
    background: {
      default: '#f2f5f6',
      paper: '#ebeff0',
    },
},
};
import { CreateWrapper, EditWrapper, ListWrapper } from './Wrapper';
import { ReferenceField, ReferenceInput } from 'react-admin';
import icon from '@mui/icons-material/VerifiedUser';

const name = "Systemuseraccesslicense";
const title = "System User Access License";
const recordRepresentation = (record: any) => record.systemuseraccess_id.name;

const create = (
    <CreateWrapper title={title}>
        <ReferenceInput reference="Systemuseraccess" source="systemuseraccess_id" label="User Access"/>
        <ReferenceInput reference="Systemlicense" source="systemlicense_id" label="License"/>        
    </CreateWrapper>
);

const edit = (
    <EditWrapper title={title}>
        <ReferenceInput reference="Systemuseraccess" source="systemuseraccess_id" label="User Access"/>
        <ReferenceInput reference="Systemlicense" source="systemlicense_id" label="License"/>        
    </EditWrapper>
);

const list = (
    <ListWrapper title={title}>
        <ReferenceField reference="Systemuseraccess" source="systemuseraccess_id" label="User Access">
            <ReferenceField reference="Systemuser" source="systemuser_id" />
            {" - "}
            <ReferenceField reference="Systemtenant" source="systemtenant_id" />
        </ReferenceField>            
        <ReferenceField reference="Systemlicense" source="systemlicense_id" label="License">
            <ReferenceField reference="Systemmodule" source="systemmodule_id" />
            {" - "}
            <ReferenceField reference="Systemtenant" source="systemtenant_id" />
        </ReferenceField>
    </ListWrapper>
);

const options = { label: title };
const useraccesslicense = { name, create, edit, list, recordRepresentation, icon, options };
export default useraccesslicense;
